<div id="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-5">
            <a href="https://www.madridingame.es/explorar"><p>Experience center</p></a>
            <a href="https://webesports.madridingame.es"><p>Esports Center</p></a>
            <a href="https://www.madridingame.es/crear"><p>Development center</p></a>
            <a href="https://www.madridingame.es/conocenos"><p>Conócenos</p></a>
            <a href="https://www.madridingame.es/observatorio"><p>Observatorio</p></a>
          </div>
          <div class="col-md-4">
            <a href="https://www.madridingame.es/noticias"><p>Noticias</p></a>
            <a href="https://eventos.madridingame.es/"><p>Eventos</p></a>
            <a href="https://www.madridingame.es/prensa"><p>Prensa</p></a>
            <a href="https://www.madridingame.es/contacto"><p>Contacto</p></a>
            <a href="https://www.madridingame.es/newsletter"><p>Newsletter</p></a>
          </div>
          <div class="col-md-3 text-foot foorig">
            <a href="https://www.madrid.es/portales/munimadrid/es/Inicio/Aviso-Legal/Aviso-legal?vgnextfmt=default&amp;vgnextoid=ce3e1e7b0f578010VgnVCM100000dc0ca8c0RCRD&amp;vgnextchannel=8a0f43db40317010VgnVCM100000dc0ca8c0RCRD"><p>Aviso legal y cookies</p></a>
            <a href="https://www.madrid.es/portales/munimadrid/es/Inicio/Proteccion-de-datos?vgnextfmt=default&amp;vgnextoid=2c241e7b0f578010VgnVCM100000dc0ca8c0RCRD&amp;vgnextchannel=251f43db40317010VgnVCM100000dc0ca8c0RCRD"><p>Privacidad</p></a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-md-12 mrt80">
      <div class="row">
        <div class="col-md-2">
          <img src="./images/logofooter1.png" style="width: 100%" />
        </div>
        <div class="col-md-2">
          <img src="./images/logofooter2.png" style="width: 100%" />
        </div>
        <div class="col-md-2">
          <img src="./images/logofooter3.png" style="width: 100%" />
        </div>
        <div class="col-md-2">
          <img src="./images/logofooter4.png" style="width: 100%" />
        </div>
        <div class="col-md-2">
          <img src="./images/logofooter5.png" style="width: 100%" />
        </div>
        <div class="col-md-2">
          <img src="./images/logofooter6.png" style="width: 100%" />
        </div>
      </div>
    </div> -->
  </div>
</div>
