<div
  class="modal-body confirm mt-5 mb-5"
  *ngIf="email && status === ReservaStatus.ReservaValida"
>
  <h4
    class="font-Lato-bold text-lg text-white text-center mb-4 {{
      textColorClass
    }}"
  >
    {{ title }}:
  </h4>

  <p class="font-Lato-bold text-lg text-white text-center pt-4 mb-0">
    {{ email }}
  </p>

  <p class="font-Lato-light text-lg text-white text-center pb-4">
    tiene una reserva
    <span *ngIf="type === ValidateBookingType.OtherDay; else bookingTime">
      dentro de
      <span class="font-Lato-bold {{ textColorClass }}"
        >{{ numberDay }} días
      </span>
    </span>

    <ng-template #bookingTime>
      a las
      <span class="font-Lato-bold {{ textColorClass }}">
        {{ reserveDate | date : 'HH:mm' }} h
      </span>
      <span class="text-grey" *ngIf="type === ValidateBookingType.OutOfTime">
        ({{ numberHours }} horas más tarde)
      </span>
    </ng-template>
  </p>

  <div *ngIf="numVisitor && currentVisitor" class="pb-2">
    <p
      class="font-Lato-light text-lg text-white text-center mb-0 {{
        textUsersColorClass
      }}"
    >
      Usuarios: {{ currentVisitor }} / {{ numVisitor }}
    </p>
  </div>

  <div
    class="row g-0 flex-column justify-content-center mt-4 align-items-center"
  >
    <div
      *ngIf="type !== ValidateBookingType.OtherDay"
      class="button-triangle-wrapper mb-3 {{ buttonValidateClass }}"
    >
      <button class="text-base" (click)="onValidate.emit()">Validar</button>
    </div>
    <div class="button-triangle-wrapper">
      <button class="text-base" (click)="onClose.emit()">Cancelar</button>
    </div>
  </div>
</div>

<div
  class="modal-body confirm mt-5 mb-5"
  *ngIf="status === ReservaStatus.ReservaLlena"
>
  <h4
    class="font-Lato-bold text-lg text-white text-center mb-4 {{
      textColorClass
    }}"
  >
    {{ title }}:
  </h4>

  <p class="font-Lato-bold text-lg text-white text-center pt-4 mb-0">
    {{ email }}
  </p>

  <p class="font-Lato-light text-lg text-white text-center pb-4">
    tiene una reserva a las
    <span class="font-Lato-bold {{ textColorClass }}">
      {{ reserveDate | date : 'HH:mm' }}h
    </span>
  </p>

  <div *ngIf="numVisitor && currentVisitor" class="pb-2">
    <p
      class="font-Lato-light text-lg text-white text-center mb-0 {{
        textColorClass
      }}"
    >
      Usuarios:
      <span class="font-Lato-bold">
        {{ currentVisitor }} / {{ numVisitor }}
      </span>
    </p>
  </div>

  <div
    class="row g-0 flex-column justify-content-center mt-4 align-items-center"
  >
    <div class="button-triangle-wrapper mb-3">
      <button class="text-base" (click)="onValidate.emit()">Validar</button>
    </div>
    <div class="button-triangle-wrapper">
      <button class="text-base" (click)="onClose.emit()">Cancelar</button>
    </div>
  </div>
</div>
<div
  class="modal-body confirm mt-5 mb-5"
  *ngIf="status === ReservaStatus.SinReserva"
>
  <h4
    class="font-Lato-bold text-lg text-white text-center mb-4 {{
      textColorClass
    }}"
  >
    Sin Reserva
  </h4>

  <div class="row g-0 justify-content-center mt-4 align-items-center">
    <div class="button-triangle-wrapper">
      <button class="text-base" (click)="onClose.emit()">Cancelar</button>
    </div>
  </div>
</div>
<div
  class="modal-body confirm mt-5 mb-5"
  *ngIf="status === ReservaStatus.HorarioNoCoincide"
>
  <h4
    class="font-Lato-bold text-lg text-white text-center mb-4 {{
      textColorClass
    }}"
  >
    {{ title }}:
  </h4>

  <p class="font-Lato-bold text-lg text-white text-center pt-4 mb-0">
    {{ email }}
  </p>

  <p class="font-Lato-light text-lg text-white text-center mb-0">
    tiene una reserva el
  </p>
  <p
    class=" text-lg text-white text-center font-Lato-bold pb-4 {{
      textColorClass
    }}"
  >
    {{ reserveDate | date : 'dd/MM/YYYY' }} -
    {{ reserveDate | date : 'HH:mm' }}h
  </p>

  <div class="row g-0 justify-content-center mt-4 align-items-center">
    <div class="button-triangle-wrapper">
      <button class="text-base" (click)="onClose.emit()">Cancelar</button>
    </div>
  </div>
</div>
