import { QRCodeCheckAnswerDTO } from 'src/app/shared/dtos';
import { QRCodeCheckAnswer } from 'src/app/shared/models';

export class QRCodeCheckAnswerMapper {
  static fromDto(answerDTO: QRCodeCheckAnswerDTO): QRCodeCheckAnswer {
    return QRCodeCheckAnswer.create({
      status: answerDTO.status,
      userId: answerDTO.user_id,
      bookingCode: answerDTO.reserva_code,
      visitDate: answerDTO.visita_date
        ? new Date(answerDTO.visita_date)
        : undefined,
      users: answerDTO.users,
      numUsersValidated: answerDTO.num_users_validated,
    });
  }
}
