import { UserDTO } from 'src/app/shared/dtos';
import { roleMap, User, UserTypeEnum } from 'src/app/shared/models';

export class UserMapper {
  static fromDto(userDto: UserDTO): User {
    return User.create({
      id: userDto.user_id,
      role: userDto.rol ? roleMap[userDto.rol] : UserTypeEnum.Client,
      bookingCode: userDto.reserva_code,
      visitorNum: userDto.num_visitor,
      currentVisitor: userDto.current_visitor,
      bookingType: userDto.reserve_type,
      visitDate: userDto.visita_date
        ? new Date(userDto.visita_date)
        : undefined,
    });
  }

  static toDto(user: User): UserDTO {
    return {
      user_id: user.id,
      rol: roleMap[user.role],
      reserva_code: user.bookingCode,
      num_visitor: user.visitorNum,
      current_visitor: user.currentVisitor,
      reserve_type: user.bookingType,
      visita_date: user.visitDate?.toISOString(),
    };
  }

  static arrayToDto(users: Array<User>): Array<UserDTO> {
    return users.map((user) => UserMapper.toDto(user));
  }

  static arrayFromDto(usersDTOs: Array<UserDTO>): Array<User> {
    return usersDTOs.map((dto) => UserMapper.fromDto(dto));
  }
}
