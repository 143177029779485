import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services';

enum MenuOptionEnum {
  booking,
  myBookings,
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  loginDisplay = false;
  selectedOption: MenuOptionEnum = MenuOptionEnum.booking;
  MenuOptionEnum = MenuOptionEnum;

  private subscriptions: Subscription;

  constructor(private authService: AuthService, private router: Router) {
    this.subscriptions = this.authService.loggedUser$.subscribe(
      (isLoggedUser: boolean) => {
        this.loginDisplay = !isLoggedUser;
      },
    );
  }

  ngOnInit(): void {
    const { url } = this.router.routerState.snapshot;
    let currentUrl = url;
    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          currentUrl = event.urlAfterRedirects;
          if (currentUrl === '/reservas') {
            this.selectedOption = MenuOptionEnum.booking;
          } else if (currentUrl === '/mis-reservas') {
            this.selectedOption = MenuOptionEnum.myBookings;
          }
        }
      }),
    );
    if (currentUrl === '/reservas') {
      this.selectedOption = MenuOptionEnum.booking;
    } else if (currentUrl === '/mis-reservas') {
      this.selectedOption = MenuOptionEnum.myBookings;
    }
  }

  logout() {
    this.authService.logout();
  }

  menuOption(option: MenuOptionEnum) {
    this.selectedOption = option;
    if (option === MenuOptionEnum.booking) {
      this.router.navigate(['/reservas']);
    } else if (option === MenuOptionEnum.myBookings) {
      this.router.navigate(['/mis-reservas']);
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
