import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/shared/models';

@Component({
  selector: 'app-booking-card',
  templateUrl: './booking-card.component.html',
  styleUrls: ['./booking-card.component.scss'],
})
export class BookingCardComponent implements OnInit {
  @Input() user?: User;
  @Output() onCardPressed = new EventEmitter<string>();
  @Output() onXPressed = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onCardSelected(bookingCode: string | undefined): void {
    if (bookingCode) {
      this.onCardPressed.emit(bookingCode);
    }
  }
  onPressX(bookingCode: string | undefined, event: MouseEvent): void {
    event.stopPropagation();
    if (bookingCode) {
      this.onXPressed.emit(bookingCode);
    }
  }
}
