<div
  class="card visit-card border-left-card animated cursor-pointer"
  id="visit-card"
  *ngIf="visit"
  [ngClass]="{
    closed: visit.activityStatus === ActivityStatusEnum.End,
    'on-going': visit.activityStatus === ActivityStatusEnum.InProgress,
    open: visit.activityStatus === ActivityStatusEnum.Waiting
  }"
  (click)="onCardSelected(visit)"
>
  <div class="row g-0 flex-nowrap">
    <div class="col-5 my-auto">
      <p class="mb-0 font-Lato-bold text-white text-xxl text-nowrap">
        {{ visit.code }}
      </p>
    </div>
    <div class="col my-auto">
      <p class="mb-0 font-Lato-light text-white text-xxxs text-nowrap">
        {{ visit.date | date : 'dd/MM/yyyy' }}
        <strong class="font-Lato-bold">
          {{ visit.date | date : 'HH:mm' }}h
        </strong>
      </p>
    </div>
    <div class="col-2 text-center">
      <p
        class="mb-0 font-Lato-light text-white text-xxxs text-uppercase text-nowrap"
      >
        {{ Visit.getCategoryText(visit.category) }}
      </p>
      <p class="mb-0 font-Lato-bold text-white text-xs">
        ({{ visit.numUsers }}/{{ visit.visitSize }})
      </p>
    </div>
  </div>
</div>
