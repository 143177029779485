import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent implements OnInit {
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  async openModal(
    content: TemplateRef<HTMLElement>,
    size: 'sm' | 'lg' | 'xl' | string = 'lg',
  ): Promise<void> {
    const modalRef: NgbModalRef = this.modalService.open(content, {
      centered: true,
      backdrop: 'static',
      size,
    });
    try {
      await modalRef.result;
    } catch (error) {
      // TODO: handle error
    } finally {
      return;
    }
  }
}
