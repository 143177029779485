import { BookingType } from 'src/app/shared/models/Booking';

export enum VisitCategoryEnum {
  normal = 'normal',
  child = 'child',
}

export enum ActivityStatusEnum {
  Waiting = 'waiting',
  End = 'end',
  InProgress = 'InProgress',
}

interface VisitProps {
  id: number;
  code: string;
  date: Date;
  category: VisitCategoryEnum;
  bookingType: BookingType;
  visitSize: number;
  duration: number;
  activityStatus: ActivityStatusEnum;
  numUsers: number;
}

export class Visit {
  readonly id: number;
  readonly code: string;
  readonly date: Date;
  readonly category: VisitCategoryEnum;
  readonly bookingType: BookingType;
  readonly visitSize: number;
  readonly duration: number;
  readonly activityStatus: ActivityStatusEnum;
  readonly numUsers: number;

  private constructor(props: VisitProps) {
    this.id = props.id;
    this.code = props.code;
    this.date = props.date;
    this.category = props.category;
    this.bookingType = props.bookingType;
    this.visitSize = props.visitSize;
    this.duration = props.duration;
    this.activityStatus = props.activityStatus;
    this.numUsers = props.numUsers;
  }

  static create(props: VisitProps): Visit {
    return new Visit(props);
  }

  static readonly categoryMap: { [key in VisitCategoryEnum]?: string } = {
    [VisitCategoryEnum.normal]: 'NORMAL',
    [VisitCategoryEnum.child]: 'INFANTIL',
  };

  static getCategoryText(category: VisitCategoryEnum): string {
    return this.categoryMap[category] || 'UNKNOWN';
  }
}
