import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';
import { FailedComponent } from './failed/failed.component';
import {
  ControlLayoutComponent,
  DashboardLayoutComponent,
  LandingLayoutComponent,
} from 'src/app/shared/layouts';
import { MyBookingsComponent } from './my-bookings/my-bookings.component';
import { LandingComponent } from './landing/landing.component';
import { MsalGuard } from '@azure/msal-angular/dist';
import { BookingsComponent } from './bookings/bookings.component';
import {
  IsClientLoggedGuard,
  IsControlLoggedGuard,
} from 'src/app/shared/guards';
import { VisitsComponent } from 'src/app/visits/visits.component';
import { VisitComponent } from 'src/app/visit/visit.component';

const routes: Routes = [
  {
    path: '',
    component: LandingLayoutComponent,
    children: [
      // {
      //     path: 'profile',
      //     component: ProfileComponent,
      //     canActivate: [MsalGuard]
      // },
      {
        path: '',
        component: LandingComponent,
      },
      {
        path: 'login-failed',
        component: FailedComponent,
      },
    ],
  },
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [MsalGuard],
    children: [
      // {
      //   path: '',
      //   pathMatch: 'full',
      //   redirectTo: 'reservas',
      // },
      {
        path: 'reservas',
        canActivate: [IsClientLoggedGuard],
        component: BookingsComponent,
      },
      {
        path: 'mis-reservas',
        canActivate: [IsClientLoggedGuard],
        component: MyBookingsComponent,
      },
    ],
  },
  {
    path: 'visitas',
    component: ControlLayoutComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        canActivate: [IsControlLoggedGuard],
        component: VisitsComponent,
      },
      {
        path: ':id',
        canActivate: [IsControlLoggedGuard],
        component: VisitComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
