<h1
  class="font-Lato-light text-4xl text-white d-flex align-items-center justify-content-center mb-3"
>
  Pases MIG Station &copy;
  <span class="ms-3 d-flex" placement="top" ngbTooltip="+ Info">
    <img
      src="assets/images/icon_info.svg"
      alt="info"
      (click)="openModal(aboutPasses)"
      class="cursor-pointer"
    />
  </span>
</h1>

<ng-template #aboutPasses let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <h3
      class="font-Lato-regular text-xl text-white d-flex flex-column-reverse flex-lg-row align-items-center justify-content-center mb-3 text-center"
    >
      Sobre los pases de la MIG Station
    </h3>
    <p class="font-Lato-light text-xxxs text-white text-center d-lg-none">
      (*) Acceso gratuito
    </p>
    <p class="font-Lato-regular text-xs text-white">
      Te damos la bienvenida al Discovery Center, el espacio que acoge la MIG
      Station, donde podrás sumergirte en una experiencia gamer única. Ya sea
      que vengas solo o en grupo, te garantizamos una jornada inolvidable.
    </p>
  </div>
  <div class="modal-footer d-none d-lg-flex">
    <p class="font-Lato-light text-xxxs text-white">(*) Acceso gratuito</p>
  </div>
</ng-template>
