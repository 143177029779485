<section class="visits-page" id="visits-page">
  <h1 class="title text-white font-Lato-light text-xl text-center animated">
    CONTROL DE ACCESO
  </h1>
  <section class="list-section">
    <div
      class="spinner-border m-auto"
      role="status"
      *ngIf="showLoading; else showVisits"
    ></div>

    <ng-template #showVisits>
      <div
        class="visits-list animated"
        *ngIf="visits.length > 0; else noVisits"
      >
        <app-visit-card
          #visitCardItem
          *ngFor="let visit of visits"
          [visit]="visit"
          (onCardPressed)="onCardClicked($event)"
        ></app-visit-card>
      </div>
    </ng-template>
  </section>
</section>

<ng-template #noVisits>
  <div class="no-visits-text text-white">No hay visitas para hoy.</div>
</ng-template>
