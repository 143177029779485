import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookingType, ReservaStatus } from 'src/app/shared/models';

enum ValidateBookingType {
  GeneralValid,
  GroupValid,
  OutOfTime,
  OtherDay,
}

@Component({
  selector: 'app-validate-booking-modal',
  templateUrl: './validate-booking-modal.component.html',
  styleUrls: ['./validate-booking-modal.component.scss'],
})
export class ValidateBookingModalComponent implements OnInit {
  @Input() status: ReservaStatus | null = null;
  @Input() bookingType: BookingType | null = null;
  @Input() reserveDate: Date | null = null;
  @Input() email: string = '';
  @Input() emailGroup: string = '';
  @Input() numVisitor: number = 0;
  @Input() currentVisitor: number = 0;
  @Output() onClose = new EventEmitter<void>();
  @Output() onValidate = new EventEmitter<void>();

  textColorClass = '';
  textUsersColorClass = '';
  buttonValidateClass = '';
  title = '';
  type: ValidateBookingType | null = ValidateBookingType.GroupValid;
  numberDay = 0;
  numberHours = 0;

  ValidateBookingType: typeof ValidateBookingType = ValidateBookingType;
  ReservaStatus: typeof ReservaStatus = ReservaStatus;

  constructor() {}

  ngOnInit(): void {
    if (!this.status || !this.reserveDate) return;

    const today = new Date();
    const { days, hours } = this.dateDifference(today, this.reserveDate);
    let type =
      this.bookingType === BookingType.particular
        ? ValidateBookingType.GeneralValid
        : ValidateBookingType.GroupValid;
    if (days === 0) {
      if (hours < 0) {
        type = ValidateBookingType.OutOfTime;
        this.numberHours = Math.abs(hours);
      }
    } else {
      type = ValidateBookingType.OtherDay;
    }

    if (type === ValidateBookingType.GeneralValid) {
      this.textColorClass = 'text-green';
      this.buttonValidateClass = 'green';
      this.title = 'Reserva válida';
    } else if (type === ValidateBookingType.GroupValid) {
      this.textColorClass = 'text-green';
      this.buttonValidateClass = 'green';
      this.title = 'Reserva GRUPAL válida';
      this.title = 'Reserva válida'; // TODO: Check
      // } else if (type === ValidateBookingType.GroupValid) {
      // TODO GroupValid but with Users in 'text-purple'
      // this.textUsersColorClass = 'text-purple';
    } else if (type === ValidateBookingType.OtherDay) {
      this.title = 'Reserva otro día';
      this.textColorClass = 'text-purple';
      this.buttonValidateClass = '';
      this.numberDay = days;
    } else if (type === ValidateBookingType.OutOfTime) {
      this.title = 'Reserva fuera de hora';
      this.textColorClass = 'text-white';
      this.buttonValidateClass = '';
    }

    if (this.status === ReservaStatus.ReservaLlena) {
      this.title = 'Reserva completa';
      this.textColorClass = 'text-purple';
    }
    if (this.status === ReservaStatus.HorarioNoCoincide) {
      this.title = 'Reserva fuera de hora';
      this.textColorClass = '';
    }

    this.type = type;
  }

  dateDifference(date1: Date, date2: Date) {
    const minus = date2.getTime() < date1.getTime();
    const diffInMs = Math.abs(date2.getTime() - date1.getTime());

    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const diffInHours = Math.floor(
      (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );

    return {
      days: diffInDays,
      hours: minus ? diffInHours * -1 : diffInHours,
    };
  }
}
