<div
  class="header top-0 d-flex justify-content-between align-items-center py-2 pe-4"
>
  <div class="pe-6 container-logo d-flex me-4">
    <img alt="Logo" src="assets/logo.png" />
  </div>

  <div class="d-flex nav-menu">
    <a
      [ngClass]="{ active: selectedOption === MenuOptionEnum.booking }"
      class="me-3"
      (click)="menuOption(MenuOptionEnum.booking)"
      >Pases</a
    >
    <a
      [ngClass]="{ active: selectedOption === MenuOptionEnum.myBookings }"
      (click)="menuOption(MenuOptionEnum.myBookings)"
      >Mis reservas</a
    >
  </div>

  <div class="me-2" style="flex: 1; display: flex; justify-content: flex-end">
    <button
      class="d-flex burger-menu-icon button-type-text"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRight"
      aria-controls="offcanvasRight"
    >
      <img
        width="40px"
        alt="burger menu icon"
        src="assets/burger-menu-icon.svg"
      />
    </button>

    <div
      class="offcanvas offcanvas-end w-100"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="burger-menu-modal">
        <button
          class="d-flex justify-content-end button-type-text w-100"
          aria-label="Close"
        >
          <img
            data-bs-dismiss="offcanvas"
            width="40px"
            alt="burger menu icon"
            src="assets/close-icon.svg"
          />
        </button>

        <div
          class="container-options d-flex justify-content-between align-items-center flex-column"
        >
          <div
            class="d-flex align-items-center flex-column justify-content-center h-100"
          >
            <a
              data-bs-dismiss="offcanvas"
              [ngClass]="{ active: selectedOption === MenuOptionEnum.booking }"
              class="me-3 mb-5"
              (click)="menuOption(MenuOptionEnum.booking)"
              >Pases</a
            >
            <a
              data-bs-dismiss="offcanvas"
              [ngClass]="{
                active: selectedOption === MenuOptionEnum.myBookings
              }"
              (click)="menuOption(MenuOptionEnum.myBookings)"
              >Mis reservas</a
            >
          </div>

          <div *ngIf="!loginDisplay" class="button-triangle-wrapper">
            <button class="button" *ngIf="!loginDisplay" (click)="logout()">
              Cerrar sesión
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!loginDisplay" class="button-triangle-wrapper ms-2">
      <button class="button" (click)="logout()">Cerrar sesión</button>
    </div>
  </div>
</div>
