import { MonthlyAvailabilityDTO } from 'src/app/shared/dtos';
import { MonthlyAvailability } from 'src/app/shared/models';
import { dateToString, stringToDate } from 'src/app/shared/utils';

export class MonthlyAvailabilityMapper {
  static toDto(
    monthlyAvailability: MonthlyAvailability,
  ): MonthlyAvailabilityDTO {
    return {
      fecha: monthlyAvailability.date
        ? dateToString(monthlyAvailability.date)
        : null,
      disponibilidad: monthlyAvailability.availability,
    };
  }

  static fromDto(
    monthlyAvailabilityDTO: MonthlyAvailabilityDTO,
  ): MonthlyAvailability {
    return {
      date: monthlyAvailabilityDTO.fecha
        ? stringToDate(monthlyAvailabilityDTO.fecha)
        : null,
      availability: monthlyAvailabilityDTO.disponibilidad,
    };
  }

  static arrayToDto(
    monthlyAvailability: Array<MonthlyAvailability>,
  ): Array<MonthlyAvailabilityDTO> {
    return monthlyAvailability.map((mAvailability) =>
      MonthlyAvailabilityMapper.toDto(mAvailability),
    );
  }

  static arrayFromDto(
    monthlyAvailabilityDTO: Array<MonthlyAvailabilityDTO>,
  ): Array<MonthlyAvailability> {
    return monthlyAvailabilityDTO.map((mAvailability) =>
      MonthlyAvailabilityMapper.fromDto(mAvailability),
    );
  }
}
