import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export function stringToDate(dateString: string): Date | null {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    console.error("Format invalid. Use 'YYYY-MM-DD'.");
    return null;
  }

  const dateObject = new Date(dateString);

  if (isNaN(dateObject.getTime())) {
    console.error('Invalid date.');
    return null;
  }

  return dateObject;
}

export function dateToString(date: Date): string {
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date.');
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function dateToNgbDateStruct(date: Date): NgbDateStruct {
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
}

export function formatDateToSpanishString(date: Date): string {
  const daysOfWeek = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  const monthsOfYear = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];

  const utcDayOfWeek = daysOfWeek[date.getUTCDay()];
  const utcDay = date.getUTCDate();
  const utcMonth = monthsOfYear[date.getUTCMonth()];
  const utcYear = date.getUTCFullYear();
  const utcHours = date.getUTCHours().toString().padStart(2, '0');
  const utcMinutes = date.getUTCMinutes().toString().padStart(2, '0');

  return `${utcDayOfWeek} ${utcDay}, ${utcMonth} ${utcYear} <b>${utcHours}:${utcMinutes} h</b>`;
}
