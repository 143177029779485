interface ApiConfigProps {
  id: number;
  generalReservationLimit: number;
  groupReservationLimit: number;
  generalSchedule: string;
  collectiveSchedule: string;
  createdAt: Date;
  updatedAt: Date;
}

export class ApiConfig {
  readonly id: number;
  readonly generalReservationLimit: number;
  readonly groupReservationLimit: number;
  readonly generalSchedule: string;
  readonly collectiveSchedule: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  private constructor(props: ApiConfigProps) {
    this.id = props.id;
    this.generalReservationLimit = props.generalReservationLimit;
    this.groupReservationLimit = props.groupReservationLimit;
    this.generalSchedule = props.generalSchedule;
    this.collectiveSchedule = props.collectiveSchedule;
    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }

  static create(props: ApiConfigProps): ApiConfig {
    return new ApiConfig(props);
  }
}
