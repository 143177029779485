import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, lastValueFrom, of } from 'rxjs';
import { QRCodeCheckAnswerDTO, UserDTO, VisitDTO } from 'src/app/shared/dtos';
import {
  QRCodeCheckAnswerMapper,
  UserMapper,
  VisitMapper,
} from 'src/app/shared/mappers';
import {
  USERS_NOT_VALIDATED_MOCK,
  USERS_VALIDATED_MOCK,
  VISITS_MOCK,
} from 'src/app/shared/mocks';
import {
  QRCodeCheckAnswer,
  User,
  Visit,
  VisitCategoryEnum,
} from 'src/app/shared/models';
import { environment } from 'src/environments/environment';

interface VisitIdProps {
  visitId: number;
}
interface ChangeVisitCategoryProps extends VisitIdProps {
  category: VisitCategoryEnum;
}
interface CheckBookingVisitProps extends VisitIdProps {
  bookingId: string;
}
interface JoinPartyProps extends CheckBookingVisitProps {
  userId: string;
}

interface VisitInfo {
  visit: Visit | undefined;
  users: User[];
}

@Injectable({
  providedIn: 'root',
})
export class VisitService {
  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  async getTodayVisits(): Promise<Visit[]> {
    const url = `${this.apiUrl}/visitas/hoy`;
    if (environment.useMocks) {
      return lastValueFrom(
        of(VisitMapper.arrayFromDto(VISITS_MOCK)).pipe(delay(2000)),
      );
    }
    const response = await lastValueFrom(this.http.get<VisitDTO[]>(url));
    return VisitMapper.arrayFromDto(response);
  }

  async changeVisitCategory(props: ChangeVisitCategoryProps): Promise<void> {
    const { visitId, category } = props;
    const url = `${this.apiUrl}/visitas/${visitId}/category`;

    const body = {
      category,
    };
    await lastValueFrom(this.http.put(url, body));
  }

  async getBookingUsersValidated(props: VisitIdProps): Promise<User[]> {
    const { visitId } = props;
    const url = `${this.apiUrl}/visitas/${visitId}/usuarios`;

    if (environment.useMocks) {
      return lastValueFrom(
        of(UserMapper.arrayFromDto(USERS_NOT_VALIDATED_MOCK)).pipe(delay(1000)),
      );
    }
    const response = await lastValueFrom(this.http.get<UserDTO[]>(url));
    return UserMapper.arrayFromDto(response);
  }

  async getBookingUsersNotValidated(props: VisitIdProps): Promise<User[]> {
    const { visitId } = props;
    const url = `${this.apiUrl}/visitas/${visitId}/reservas/usuarios`;

    if (environment.useMocks) {
      return lastValueFrom(
        of(UserMapper.arrayFromDto(USERS_VALIDATED_MOCK)).pipe(delay(1000)),
      );
    }
    const response = await lastValueFrom(this.http.get<UserDTO[]>(url));
    return UserMapper.arrayFromDto(response);
  }

  async checkQRBookingWithVisit(
    props: CheckBookingVisitProps,
  ): Promise<QRCodeCheckAnswer> {
    const { visitId, bookingId } = props;
    const url = `${this.apiUrl}/reservas/${bookingId}/visitas/${visitId}/comprobar`;
    const response = await lastValueFrom(
      this.http.get<QRCodeCheckAnswerDTO>(url),
    );
    return QRCodeCheckAnswerMapper.fromDto(response);
  }

  async getVisitInfo(props: VisitIdProps): Promise<VisitInfo> {
    const { visitId } = props;
    let visit: Visit | undefined;
    let uniqueUsers: User[] = [];
    try {
      const [dailyVisitsResult, usersNotValidatedResult, usersValidatedResult] =
        await Promise.allSettled([
          this.getTodayVisits(),
          this.getBookingUsersNotValidated({ visitId }),
          this.getBookingUsersValidated({ visitId }),
        ]);

      const dailyVisits =
        dailyVisitsResult.status === 'fulfilled'
          ? dailyVisitsResult.value
          : undefined;
      const usersNotValidated =
        usersNotValidatedResult.status === 'fulfilled'
          ? usersNotValidatedResult.value
          : [];
      const usersValidated =
        usersValidatedResult.status === 'fulfilled'
          ? usersValidatedResult.value
          : [];
      visit = dailyVisits?.find((v) => v.id === visitId);

      const allUsers = [...usersNotValidated, ...usersValidated];

      // If user is in both lists, we keep the validated one
      uniqueUsers = Array.from(
        new Map(allUsers.map((user) => [user.id, user])).values(),
      );
      uniqueUsers.sort((a, b) => {
        if (a.currentVisitor === undefined) return 1;
        if (b.currentVisitor === undefined) return -1;
        return a.currentVisitor - b.currentVisitor;
      });
    } catch (error) {
      // TODO: handle error
    }

    return {
      visit,
      users: uniqueUsers,
    };
  }

  async startExperience(props: VisitIdProps): Promise<void> {
    const url = `${this.apiUrl}/start_experience`;
    const { visitId } = props;
    const body = {
      visita_id: visitId,
    };
    await lastValueFrom(this.http.post(url, body));
  }

  async joinParty(props: JoinPartyProps): Promise<void> {
    const url = `${this.apiUrl}/join_party`;
    const { visitId, bookingId, userId } = props;
    const body = {
      visita_id: visitId,
      reserva_id: bookingId,
      user_id: userId,
    };
    await lastValueFrom(this.http.post(url, body));
  }
}
