<div
  class="d-flex justify-content-center flex-column align-items-center my-bookings"
>
  <app-title></app-title>

  <div
    *ngIf="showLoading; else showBookings"
    class="spinner-border"
    role="status"
  ></div>
  <ng-template #showBookings>
    <div class="col-12 col-md-7 col-lg-5 mb-2">
      <h2 class="text-white font-Lato-light text-xxs noted">Mis reservas</h2>
      <span class="ms-2 text-white font-Lato-light text-xxs"
        >[{{ booking ? '1' : '0' }}/1]</span
      >
    </div>
    <div *ngIf="booking" class="border-left-card col-12 col-md-7 col-lg-5">
      <div class="d-flex align-items-center">
        <span class="font-Lato-bold text-xxl me-3"
          >{{ booking.numVisitor }}x</span
        >
        <div class="d-flex flex-column me-3" style="flex: 1">
          <span class="font-Lato-bold text-xs">
            {{
              booking.reserveType === BookingType.particular
                ? 'Pase general'
                : 'Pase especial'
            }}
          </span>
          <span class="text-xxs">
            {{ booking.date | date : 'dd/MM/YYYY HH:mm' }} h
          </span>
        </div>

        <button
          class="button-type-text text-white font-Lato-light text-xs"
          (click)="onEdit()"
        >
          <ng-container *ngIf="!openEditMode"> Editar </ng-container>
          <ng-container *ngIf="openEditMode">
            <span class="d-flex">
              <img
                src="assets/close-icon.svg"
                alt="cerrar modo edición"
                width="16"
              />
            </span>
          </ng-container>
        </button>
      </div>
      <div *ngIf="openEditMode">
        <span class="line"></span>
        <div class="d-flex justify-content-between py-3 mx-2">
          <label class="text-white text-xs font-Lato-regular" for="quantity">
            {{
              booking.reserveType === BookingType.particular
                ? 'Pases generales'
                : 'Pases especiales'
            }}
          </label>
          <div class="input-group">
            <button
              class="btn-transparent btn-decrement"
              (click)="decrementQuantity()"
              [disabled]="quantity === 0"
            >
              <span class="d-flex">
                <img src="assets/images/btn_menos.svg" alt="Menos" />
              </span>
            </button>
            <input
              class="text-base"
              type="number"
              id="quantity"
              min="0"
              [max]="checkMaxVisitors(booking)"
              [(ngModel)]="quantity"
              (change)="updateQuantity(quantity)"
            />
            <button
              class="btn-transparent btn-increment"
              (click)="incrementQuantity()"
              [disabled]="quantity >= checkMaxVisitors(booking)"
            >
              <span class="d-flex">
                <img src="assets/images/btn_mas.svg" alt="Mas" />
              </span>
            </button>
          </div>
        </div>
        <div class="d-flex pb-3 justify-content-between mx-2">
          <button
            class="button-border-left cancel text-xxxs"
            (click)="cancelBooking(confirmBooking)"
          >
            Cancelar reserva
          </button>
          <button
            class="button-border-left text-xxxs"
            (click)="modify(booking)"
            [disabled]="!modifyIsAvailable(booking)"
          >
            Confirmar cambio
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #confirmBooking let-modal>
  <div class="modal-body py-5">
    <h3 class="font-Lato-light text-xl text-white text-center mb-3">
      ¿Estas seguro de que quieres cancelar esta reserva?
    </h3>
    <p class="text-xxxs text-white font-Lato-light text-center">
      *si cancelas tu reserva en las 24 horas previas, se bloquearan la
      solicitud de reservas por un periodo de tiempo
    </p>
    <div class="row g-0 justify-content-between mt-3">
      <div class="button-triangle-wrapper small">
        <button class="text-base" (click)="modal.close()">Mantener</button>
      </div>
      <div class="button-triangle-wrapper small">
        <button class="text-base delete-button" (click)="delete()">
          Eliminar
        </button>
      </div>
    </div>
  </div>
</ng-template>
