import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivityStatusEnum, Visit } from 'src/app/shared/models';

@Component({
  selector: 'app-visit-card',
  templateUrl: './visit-card.component.html',
  styleUrls: ['./visit-card.component.scss'],
})
export class VisitCardComponent implements OnInit {
  Visit = Visit;
  ActivityStatusEnum = ActivityStatusEnum;

  @Input() visit?: Visit;
  @Output() onCardPressed = new EventEmitter<Visit>();

  constructor(public elementRef: ElementRef) {}

  ngOnInit(): void {}

  onCardSelected(visit: Visit): void {
    if (visit.activityStatus === ActivityStatusEnum.End) return;

    this.onCardPressed.emit(visit);
  }
}
