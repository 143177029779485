import { VisitDTO } from 'src/app/shared/dtos';
import {
  VisitCategoryEnum,
  ActivityStatusEnum,
  BookingType,
} from 'src/app/shared/models';

export const VISITS_MOCK: VisitDTO[] = [
  {
    id: 1,
    code: 'VIS12345',
    date: '2024-09-05T10:30:00Z',
    category: VisitCategoryEnum.normal,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 3,
  },
  {
    id: 2,
    code: 'VIS12346',
    date: '2024-09-06T14:15:00Z',
    category: VisitCategoryEnum.child,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 5,
  },
  {
    id: 3,
    code: 'VIS12347',
    date: '2024-09-07T09:45:00Z',
    category: VisitCategoryEnum.normal,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 1,
  },
  {
    id: 4,
    code: 'VIS12348',
    date: '2024-09-08T11:00:00Z',
    category: VisitCategoryEnum.normal,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 4,
  },
  {
    id: 5,
    code: 'VIS12349',
    date: '2024-09-09T12:30:00Z',
    category: VisitCategoryEnum.child,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 2,
  },
  {
    id: 6,
    code: 'VIS12350',
    date: '2024-09-10T15:00:00Z',
    category: VisitCategoryEnum.normal,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 6,
  },
  {
    id: 7,
    code: 'VIS12351',
    date: '2024-09-11T10:45:00Z',
    category: VisitCategoryEnum.normal,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 8,
  },
  {
    id: 8,
    code: 'VIS12352',
    date: '2024-09-12T09:30:00Z',
    category: VisitCategoryEnum.child,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 2,
  },
  {
    id: 9,
    code: 'VIS12353',
    date: '2024-09-13T16:00:00Z',
    category: VisitCategoryEnum.normal,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 4,
  },
  {
    id: 10,
    code: 'VIS12354',
    date: '2024-09-14T11:15:00Z',
    category: VisitCategoryEnum.normal,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 5,
  },
  {
    id: 11,
    code: 'VIS12355',
    date: '2024-09-15T14:00:00Z',
    category: VisitCategoryEnum.child,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 3,
  },
  {
    id: 12,
    code: 'VIS12356',
    date: '2024-09-16T13:45:00Z',
    category: VisitCategoryEnum.normal,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 2,
  },
  {
    id: 13,
    code: 'VIS12357',
    date: '2024-09-17T12:00:00Z',
    category: VisitCategoryEnum.normal,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 7,
  },
  {
    id: 14,
    code: 'VIS12358',
    date: '2024-09-18T09:00:00Z',
    category: VisitCategoryEnum.child,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 1,
  },
  {
    id: 15,
    code: 'VIS12359',
    date: '2024-09-19T17:30:00Z',
    category: VisitCategoryEnum.normal,
    reserve_type: BookingType.particular,
    visite_size: 10,
    duration: 15,
    activity_status: ActivityStatusEnum.InProgress,
    num_users: 4,
  },
];
