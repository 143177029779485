<div
  class="card booking-card border-left-card animated cursor-pointer"
  id="booking-card"
  *ngIf="user"
  [ngClass]="{
    validated: user.currentVisitor,
  }"
  (click)="onCardSelected(user.bookingCode)"
>
  <div class="row g-0 align-items-center">
    <div class="col-5 my-auto">
      <p class="mb-0 font-Lato-bold text-white text-xxl">
        {{ user.bookingCode }}
      </p>
    </div>
    <div class="col text-break">
      <p class="mb-0 font-Lato-bold text-white text-xs text-break text-wrap">
        {{ user.id }}
      </p>
    </div>
    <div class="col-2 text-end">
      <ng-container *ngIf="user.currentVisitor">
        <p class="mb-0 font-Lato-light text-white text-xxxs text-uppercase">
          ({{ user.currentVisitor }}/{{ user.visitorNum }})
        </p>
      </ng-container>
      <ng-container *ngIf="!user.currentVisitor">
        <p class="mb-0 font-Lato-light text-white text-xxxs text-uppercase">
          ({{ user.visitorNum }})
        </p>
      </ng-container>
    </div>
  </div>
</div>
