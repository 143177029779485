import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.init();
  }

  fullHeight() {
    const elements = document.querySelectorAll<HTMLElement>('.js-fullheight');
    const updateHeight = () => {
      const height = window.innerHeight;
      elements.forEach((element) => {
        element.style.height = `${height}px`;
      });
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
  };

  init() {
    this.fullHeight();

    const sidebarCollapse = document.getElementById('sidebarCollapse');
    const sidebar = document.getElementById('sidebar');

    sidebarCollapse?.addEventListener('click', () => {
      sidebar?.classList.toggle('active');
    });

    // EXPLORAR
    const shape1: HTMLElement | null = document.querySelector('.shape1');
    const shape2: HTMLElement | null = document.querySelector('.shape2');
    const shape3: HTMLElement | null = document.querySelector('.shape3');
    const shapetext1: HTMLElement | null = document.querySelector('.shapetext1');
    const shapetext2: HTMLElement | null = document.querySelector('.shapetext2');

    const animateElement = (element: HTMLElement, properties: Partial<CSSStyleDeclaration>, duration: number) => {
      return new Promise<void>((resolve) => {
        Object.assign(element.style, properties);
        element.style.transition = `all ${duration}ms ease`;
        setTimeout(() => {
          resolve();
        }, duration);
      });
    };

    shape1?.addEventListener('click', async () => {
      if (shape2 && shape3 && shapetext1 && shapetext2 && shape1) {
        shape2.classList.add('zi9');
        shape3.classList.add('zi9');
        shapetext2.classList.add('zi10');
        shapetext2.style.display = 'none';

        await animateElement(shape1, { width: '200px' }, 500);
        shape1.classList.add('zi9');

        shape1.style.transition = 'transform 3000ms';
        shape1.style.transform = 'scale(60)';
        shape1.style.fill = 'url(#gradientshape1)';

        setTimeout(() => {
          window.location.href = '/explorar.html';
        }, 800);

        await animateElement(shapetext1, { top: '87%', right: '-50%' }, 500);
        shapetext1.classList.add('zi10');

        await animateElement(shapetext1, { top: '4%', right: '145px' }, 300);
        shapetext1.classList.add('zi10');

        setTimeout(() => {
          shapetext1.style.display = 'none';
        }, 200);
      }
    });

    // JUGAR
    shape2?.addEventListener('click', async () => {
      if (shape2 && shapetext2) {
        await animateElement(shape2, { width: '200px' }, 500);
        shape2.classList.add('zi9');

        shape2.style.transition = 'transform 3000ms';
        shape2.style.transform = 'scale(50)';
        shape2.style.fill = 'url(#gradientshape2)';

        setTimeout(() => {
          window.location.href = '/crear.html';
        }, 800);

        await animateElement(shapetext2, { top: '87%', right: '-50%' }, 500);
        shapetext2.classList.add('zi10');

        await animateElement(shapetext2, { top: '4%', right: '145px' }, 300);
        shapetext2.classList.add('zi10');

        setTimeout(() => {
          shapetext2.style.display = 'none';
        }, 200);
      }
    });

    shape3?.addEventListener('click', async () => {
      const shapetext3: HTMLElement | null = document.querySelector('.shapetext3');
      if (shape3 && shapetext3) {
        await animateElement(shape3, { width: '200px' }, 500);
        shape3.classList.add('zi9');

        shape3.style.transition = 'transform 3000ms';
        shape3.style.transform = 'scale(50)';

        setTimeout(() => {
          window.location.href = './jugar.html';
        }, 800);

        await animateElement(shapetext3, { top: '87%', right: '-50%' }, 500);
        shapetext3.classList.add('zi10');

        await animateElement(shapetext3, { top: '4%', right: '145px' }, 300);
        shapetext3.classList.add('zi10');

        setTimeout(() => {
          shapetext3.style.display = 'none';
        }, 200);
      }
    });

    // Submenu hide & show
    const toggleClass = (selector: string, class1: string, class2: string) => {
      const element = document.querySelector(selector);
      if (element) {
        element.classList.toggle(class1);
        element.classList.toggle(class2);
      }
    };

    document.querySelector('.submenuitem')?.addEventListener('click', () => {
      toggleClass('#submenu', 'show', 'hide');
    });

    document.querySelector('.submenuitemsocial')?.addEventListener('click', () => {
      toggleClass('#social', 'show', 'hide');
    });

    document.querySelector('.submenuitemmob')?.addEventListener('click', () => {
      toggleClass('#submenumob', 'show', 'hide');
    });

    document.querySelector('.extrabox')?.addEventListener('click', () => {
      const subxtra: HTMLElement | null = document.querySelector('.subxtra');
      if (subxtra) {
        subxtra.style.display = 'block';
        subxtra.style.opacity = '0';
        subxtra.style.transition = 'opacity 500ms ease';
        setTimeout(() => {
          subxtra.style.opacity = '1';
        }, 1);
      }
    });

    document.querySelector('.ligaextraclose')?.addEventListener('click', () => {
      const subxtra: HTMLElement | null = document.querySelector('.subxtra');
      if (subxtra) {
        subxtra.style.transition = 'opacity 500ms ease';
        subxtra.style.opacity = '0';
        setTimeout(() => {
          subxtra.style.display = 'none';
        }, 500);
      }
    });

    document.addEventListener('DOMContentLoaded', () => {
      document.addEventListener('scroll', () => {
        const bannerAnimH1: HTMLElement | null = document.querySelector('.banneranim h1');
        const footer: HTMLElement | null = document.getElementById('footer');
        const btnFixed: HTMLElement | null = document.querySelector('.btnfixed');
        const posBottomFix: HTMLElement | null = document.querySelector('.posbottomfix');

        if (bannerAnimH1 && footer && btnFixed && posBottomFix) {
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          const bottom = footer.offsetHeight + 112;

          if (scrollTop > 200) {
            bannerAnimH1.classList.add('bannertextfi', 'text-black');
          } else {
            bannerAnimH1.classList.remove('bannertextfi', 'text-black');
          }

          if (scrollTop + window.innerHeight < footer.offsetTop) {
            btnFixed.style.bottom = 'unset';
            posBottomFix.style.bottom = '0';
          } else {
            btnFixed.style.bottom = `${bottom - 100}px`;
            posBottomFix.style.bottom = `${bottom}px`;
          }
        }
      });
    });
  }
}
