import { VisitDTO } from 'src/app/shared/dtos';
import { Visit } from 'src/app/shared/models';

export class VisitMapper {
  static fromDto(visitDto: VisitDTO): Visit {
    return Visit.create({
      id: visitDto.id,
      code: visitDto.code,
      date: new Date(visitDto.date),
      category: visitDto.category,
      bookingType: visitDto.reserve_type,
      visitSize: visitDto.visite_size,
      duration: visitDto.duration,
      activityStatus: visitDto.activity_status,
      numUsers: visitDto.num_users,
    });
  }

  static toDto(visit: Visit): VisitDTO {
    return {
      id: visit.id,
      code: visit.code,
      date: visit.date.toLocaleDateString(),
      category: visit.category,
      reserve_type: visit.bookingType,
      visite_size: visit.visitSize,
      duration: visit.duration,
      activity_status: visit.activityStatus,
      num_users: visit.numUsers,
    };
  }

  static arrayToDto(visits: Array<Visit>): Array<VisitDTO> {
    return visits.map((visit) => VisitMapper.toDto(visit));
  }

  static arrayFromDto(visitsDTOs: Array<VisitDTO>): Array<Visit> {
    return visitsDTOs.map((dto) => VisitMapper.fromDto(dto));
  }
}
