import { ApiConfigDTO } from 'src/app/shared/dtos';
import { ApiConfig } from 'src/app/shared/models';

export class ApiConfigMapper {
  static fromDto(apiConfigDto: ApiConfigDTO): ApiConfig {
    return ApiConfig.create({
      id: apiConfigDto.id,
      generalReservationLimit: apiConfigDto.general_reservation_limit,
      groupReservationLimit: apiConfigDto.group_reservation_limit,
      generalSchedule: apiConfigDto.general_schedule,
      collectiveSchedule: apiConfigDto.collective_schedule,
      createdAt: new Date(apiConfigDto.created_at),
      updatedAt: new Date(apiConfigDto.updated_at),
    });
  }

  static toDto(apiConfig: ApiConfig): ApiConfigDTO {
    return {
      id: apiConfig.id,
      general_reservation_limit: apiConfig.generalReservationLimit,
      group_reservation_limit: apiConfig.groupReservationLimit,
      general_schedule: apiConfig.generalSchedule,
      collective_schedule: apiConfig.collectiveSchedule,
      created_at: apiConfig.createdAt.toISOString(),
      updated_at: apiConfig.updatedAt.toISOString(),
    };
  }
}
