export enum ReservaStatus {
  ReservaValida = 1,
  ReservaLlena = 2,
  HorarioNoCoincide = 3,
  SinReserva = 4,
}

interface QRCodeCheckAnswerProps {
  status: ReservaStatus;
  userId?: string;
  bookingCode?: string;
  visitDate?: Date;
  users?: number;
  numUsersValidated?: number;
}

export class QRCodeCheckAnswer {
  status: ReservaStatus;
  userId?: string;
  bookingCode?: string;
  visitDate?: Date;
  users?: number;
  numUsersValidated?: number;

  private constructor(props: QRCodeCheckAnswerProps) {
    this.status = props.status;
    this.userId = props.userId;
    this.bookingCode = props.bookingCode;
    this.visitDate = props.visitDate;
    this.users = props.users;
    this.numUsersValidated = props.numUsersValidated;
  }

  static create(props: QRCodeCheckAnswerProps): QRCodeCheckAnswer {
    return new QRCodeCheckAnswer(props);
  }
}
