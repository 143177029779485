<div class="layout">
  <div>
    <app-left-menu></app-left-menu>
  </div>
  <app-landing-header></app-landing-header>
  <main class="main">
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </main>
</div>
