import { DailyAvailabilityDTO } from 'src/app/shared/dtos';
import { TimeSlot } from 'src/app/shared/models';

export const DAILY_AVAILABILITY_MOCK: DailyAvailabilityDTO[] = [
  {
    id_visita: 1,
    fecha: '2024-10-01T08:00:00.000Z',
    franja: TimeSlot.morning,
    capacidad_total: 10,
    visitantes_reservados: '2',
    huecos_disponibles: '8',
    max_group: 10,
    duration: 15,
  },
  {
    id_visita: 2,
    fecha: '2024-10-01T08:15:00.000Z',
    franja: TimeSlot.morning,
    capacidad_total: 10,
    visitantes_reservados: '0',
    huecos_disponibles: '10',
    max_group: 10,
    duration: 15,
  },
  {
    id_visita: 6,
    fecha: '2024-10-01T08:15:00.000Z',
    franja: TimeSlot.morning,
    capacidad_total: 10,
    visitantes_reservados: '0',
    huecos_disponibles: '10',
    max_group: 10,
    duration: 15,
  },
  {
    id_visita: 3,
    fecha: '2024-10-01T08:30:00.000Z',
    franja: TimeSlot.morning,
    capacidad_total: 10,
    visitantes_reservados: '2',
    huecos_disponibles: '8',
    max_group: 10,
    duration: 15,
  },
  {
    id_visita: 7,
    fecha: '2024-10-01T08:30:00.000Z',
    franja: TimeSlot.morning,
    capacidad_total: 10,
    visitantes_reservados: '0',
    huecos_disponibles: '10',
    max_group: 10,
    duration: 15,
  },
  {
    id_visita: 4,
    fecha: '2024-10-01T08:45:00.000Z',
    franja: TimeSlot.morning,
    capacidad_total: 10,
    visitantes_reservados: '0',
    huecos_disponibles: '10',
    max_group: 10,
    duration: 15,
  },
];
