<section class="visit-page" id="visit-page">
  <div
    class="spinner-border m-auto"
    role="status"
    *ngIf="showLoading; else showVisit"
  ></div>

  <ng-template #showVisit>
    <section class="visit-section d-flex flex-column" *ngIf="visit">
      <div class="button-back animated" (click)="handleBack()">Atrás</div>

      <div class="visit-info animated">
        <h1
          class="title text-white font-Lato-bold text-xl text-center animated"
        >
          {{ visit.code }}
        </h1>
        <div class="d-flex justify-content-center">
          <p class="visit-details mb-0 text-white font-Lato-bold text-base">
            <span class="font-Lato-light">
              {{ visit.date | date : 'dd/MM/yyyy' }}
            </span>
            {{ visit.date | date : 'HH:mm' }}h
          </p>
        </div>
      </div>
      <div
        class="users-types d-flex justify-content-center mb-2 animated"
        *ngIf="!showQRScanner"
      >
        <div class="valid-user text-white font-Lato-regular text-xxxs">
          Usuario validado
        </div>
        <div class="booking-user text-white font-Lato-regular text-xxxs">
          Usuario con reserva
        </div>
      </div>

      <section
        class="booking-block d-flex flex-column animated"
        *ngIf="!showQRScanner"
        [ngClass]="{ 'h-100': users.length == 0 }"
      >
        <section class="list-section">
          <div class="booking-list" *ngIf="users.length > 0; else noVisits">
            <app-booking-card
              *ngFor="let user of users"
              [user]="user"
            ></app-booking-card>
          </div>
        </section>

        <div class="validate-booking-wrapper mb-3 animated">
          <button
            class="button-border-left no-extra-border d-flex align-items-center w-100 justify-content-center"
            (click)="validateBooking()"
          >
            <span class="d-flex me-2">
              <img src="assets/images/btn_mas.svg" alt="validar reserva" />
            </span>
            Validar reserva
          </button>
        </div>
      </section>
      <div
        class="start-experience-wrapper mt-auto animated"
        *ngIf="!showQRScanner && !showManualBooking"
      >
        <div class="button-triangle-wrapper mx-auto">
          <button
            class="button"
            (click)="startExperience(visit.id)"
            [disabled]="showLoadingStartExperience || users.length === 0"
          >
            <div
              class="spinner-border spinner-border-sm m-auto"
              role="status"
              *ngIf="showLoadingStartExperience; else showText"
            ></div>
            <ng-template #showText>Iniciar experiencia</ng-template>
          </button>
        </div>
      </div>

      <section
        class="qr-scanner-wrapper px-3 animated d-flex flex-column"
        *ngIf="showQRScanner && !showManualBooking"
      >
        <div class="qr-code-scanner">
          <h3 class="text-white font-Lato-bold text-base text-center mb-3">
            Leer QR
          </h3>
          <div class="qr-scanner video-container">
            <zxing-scanner
              [formats]="[BarcodeFormat.QR_CODE]"
              [device]="currentDevice"
              (scanSuccess)="handleQrCodeResult($event)"
            ></zxing-scanner>
            <!-- (scanFailure)="handleQrFailureCodeResult()" -->
            <div class="corner-borders">
              <div class="top-right"></div>
              <div class="bottom-left"></div>
            </div>
          </div>
          <div
            class="qr-reader-response text-white font-Lato-regular text-xxxs text-center mt-3"
          >
            <ng-container *ngIf="!isQRNotValid && !isCheckingQRCode">
              *Escaneando código
            </ng-container>
            <ng-container *ngIf="!isQRNotValid && isCheckingQRCode">
              *Comprobando código
            </ng-container>
            <ng-container *ngIf="isQRNotValid">
              *QR escaneado no válido
            </ng-container>
          </div>
        </div>

        <div class="manual-booking-wrapper mt-auto animated">
          <button
            class="button-border-left no-extra-border d-flex align-items-center w-100 justify-content-center"
            (click)="manualBooking()"
          >
            Añadir manualmente
          </button>
        </div>
      </section>
      <section
        class="manual-booking-wrapper-section px-3 animated d-flex flex-column"
        *ngIf="showQRScanner && showManualBooking"
      >
        <div class="manual-booking my-auto">
          <div class="manual-booking-form">
            <form
              class="manual-form"
              [formGroup]="manualBookingForm"
              (ngSubmit)="checkManualBooking()"
              autocomplete="off"
              novalidate
            >
              <div class="form-group">
                <label
                  for="bookingId"
                  class="noted text-white font-Lato-light text-xs"
                >
                  id_reserva
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="bookingId"
                  placeholder="id reserva"
                  formControlName="bookingId"
                  [ngClass]="displayFieldCss(bookingId)"
                />
                <div
                  class="invalid-feedback d-block"
                  *ngIf="bookingId.hasError('required') && bookingId.dirty"
                >
                  <p class="mb-0 text-danger text-xs">
                    id de reserva obligatorio
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label
                  for="email"
                  class="noted text-white font-Lato-light text-xs"
                >
                  correo electrónico
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="id reserva"
                  formControlName="email"
                  [ngClass]="displayFieldCss(email)"
                />
                <div
                  class="invalid-feedback d-block"
                  *ngIf="
                    (email.hasError('required') || email.hasError('email')) &&
                    email.dirty
                  "
                >
                  <p class="mb-0 text-danger text-xs">
                    email obligatorio y formato de Email
                  </p>
                </div>
              </div>

              <div class="button-triangle-wrapper mx-auto">
                <button type="submit" class="button">
                  <div
                    class="spinner-border spinner-border-sm m-auto"
                    role="status"
                    *ngIf="showLoadingAddBooking; else showText"
                  ></div>
                  <ng-template #showText>Añadir reserva</ng-template>
                </button>
              </div>
            </form>
          </div>
        </div>

        <div class="manual-booking-wrapper mt-auto animated">
          <button
            class="button-border-left no-extra-border d-flex align-items-center w-100 justify-content-center"
            (click)="showQRReader()"
          >
            Leer QR
          </button>
        </div>
      </section>
    </section>
  </ng-template>

  <div class="button-change-category-wrapper">
    <div class="button-triangle-wrapper mx-auto">
      <button class="button">
        <!-- (click)="
          changeCategory(
            this.visit?.category === VisitCategoryEnum.normal
              ? VisitCategoryEnum.child
              : VisitCategoryEnum.normal
          )
        " -->
        <div class="d-flex gap-3">
          <p
            class="text-uppercase text-xs mb-0"
            [ngClass]="
              visit?.category == VisitCategoryEnum.normal
                ? 'font-Lato-bold'
                : 'font-Lato-light'
            "
            (click)="changeCategory(VisitCategoryEnum.normal)"
          >
            NORMAL
          </p>
          <p
            class="text-uppercase text-xs mb-0"
            [ngClass]="
              visit?.category == VisitCategoryEnum.child
                ? 'font-Lato-bold'
                : 'font-Lato-light'
            "
            (click)="changeCategory(VisitCategoryEnum.child)"
          >
            EXPERIENCIAL
          </p>
        </div>
      </button>
    </div>
  </div>
</section>

<ng-template #validateBookingModal let-modal>
  <app-validate-booking-modal
    [status]="qrAnswer.status"
    [bookingType]="visit?.bookingType ?? null"
    [reserveDate]="qrAnswer.visitDate ?? null"
    [email]="qrAnswer.userId ?? ''"
    [numVisitor]="qrAnswer.users ?? 0"
    [currentVisitor]="qrAnswer.numUsersValidated ?? 0"
    (onClose)="modal.close()"
    (onValidate)="joinParty()"
  ></app-validate-booking-modal>
</ng-template>

<ng-template #noVisits>
  <div class="h-100 d-flex flex-align-center">
    <div class="no-visits-text text-white">No hay información.</div>
  </div>
</ng-template>
