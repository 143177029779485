<div class="layout">
  <div>
    <app-left-menu></app-left-menu>
  </div>
  <app-header></app-header>
  <main class="main">
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>

    <div class="schedule-container-wrapper">
      <div class="schedule-container">
        <h3 class="title text-sm">Horarios de Apertura</h3>
        <div class="d-flex">
          <div class="me-5">
            <p class="font-Lato-bold text-xxxs-sm mb-1">Horario General</p>
            <p class="font-Lato-regular text-xxxs-sm">
              De lunes a viernes de 17 h a 20 h
            </p>
            <p class="font-Lato-regular text-xxxs-sm">
              Domingos y festivos de 10 h a 20h
            </p>
          </div>
          <div>
            <p class="font-Lato-bold text-xxxs-sm mb-1">Horario Colectivos</p>
            <p class="font-Lato-regular text-xxxs-sm">
              De lunes a viernes de 10 h a 17 h
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrapper pt-5">
      <app-footer></app-footer>
    </div>
  </main>
</div>
