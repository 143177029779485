import { DailyAvailabilityDTO } from 'src/app/shared/dtos';
import { DailyAvailability, TimeSlot } from 'src/app/shared/models';

export class DailyAvailabilityMapper {
  static toDto(dailyAvailability: DailyAvailability): DailyAvailabilityDTO {
    return {
      id_visita: dailyAvailability.visitId,
      fecha: dailyAvailability.date.toISOString(),
      franja: TimeSlot[dailyAvailability.timeSlot],
      capacidad_total: dailyAvailability.totalCapacity,
      visitantes_reservados: dailyAvailability.reservedVisitors.toString(),
      huecos_disponibles: dailyAvailability.availableSpots.toString(),
      max_group: dailyAvailability.maxGroup,
      duration: dailyAvailability.duration,
    };
  }

  static fromDto(
    dailyAvailabilityDTO: DailyAvailabilityDTO,
  ): DailyAvailability {
    return DailyAvailability.create({
      visitId: dailyAvailabilityDTO.id_visita,
      date: new Date(dailyAvailabilityDTO.fecha),
      timeSlot: TimeSlot[dailyAvailabilityDTO.franja],
      totalCapacity: dailyAvailabilityDTO.capacidad_total,
      reservedVisitors: parseInt(dailyAvailabilityDTO.visitantes_reservados),
      availableSpots: parseInt(dailyAvailabilityDTO.huecos_disponibles),
      maxGroup: dailyAvailabilityDTO.max_group,
      duration: dailyAvailabilityDTO.duration,
    });
  }

  static arrayToDto(
    dailyAvailabilities: Array<DailyAvailability>,
  ): Array<DailyAvailabilityDTO> {
    return dailyAvailabilities.map((availability) =>
      DailyAvailabilityMapper.toDto(availability),
    );
  }

  static arrayFromDto(
    dailyAvailabilityDTOs: Array<DailyAvailabilityDTO>,
  ): Array<DailyAvailability> {
    return dailyAvailabilityDTOs.map((dto) =>
      DailyAvailabilityMapper.fromDto(dto),
    );
  }
}
