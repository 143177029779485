import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  private readonly I18N_VALUES = {
    es: {
      weekdays: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
  };

  getWeekdayShortName(weekday: number): string {
    return this.I18N_VALUES.es.weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return this.I18N_VALUES.es.months[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.I18N_VALUES.es.months[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number): string {
    return this.I18N_VALUES.es.weekdays[weekday - 1];
  }
}
