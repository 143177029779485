import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services';

enum MenuOptionEnum {
  booking,
  myBookings,
}

@Component({
  selector: 'app-header-control',
  templateUrl: './header-control.component.html',
  styleUrls: ['./header-control.component.scss'],
})
export class HeaderControlComponent implements OnInit, OnDestroy {
  loginDisplay = false;

  showMenu = true;

  private subscriptions: Subscription = new Subscription();

  constructor(private authService: AuthService, private router: Router) {
    this.subscriptions.add(
      this.authService.loggedUser$.subscribe((isLoggedUser: boolean) => {
        this.loginDisplay = !isLoggedUser;
      }),
    );

    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          const currentUrl = event.urlAfterRedirects;
          const regex = /\/visitas\//;
          this.showMenu = !regex.test(currentUrl);
        }
      }),
    );
  }

  ngOnInit(): void {}

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
