import { UserDTO } from 'src/app/shared/dtos';
import { BookingType } from 'src/app/shared/models';

export const USERS_NOT_VALIDATED_MOCK: UserDTO[] = [
  {
    user_id: 'user1',
    reserva_code: 'res1CODE',
    current_visitor: 1,
    num_visitor: 5,
  },
  {
    user_id: 'user2',
    reserva_code: 'res2CODE',
    current_visitor: 1,
    num_visitor: 5,
  },
  {
    user_id: 'user3',
    reserva_code: 'res3CODE',
    current_visitor: 1,
    num_visitor: 5,
  },
  {
    user_id: 'user4',
    reserva_code: 'res4CODE',
    current_visitor: 1,
    num_visitor: 5,
  },
  {
    user_id: 'user5',
    reserva_code: 'res5CODE',
    current_visitor: 1,
    num_visitor: 5,
  },
  {
    user_id: 'user6',
    reserva_code: 'res6CODE',
    current_visitor: 1,
    num_visitor: 5,
  },
  {
    user_id: 'user7',
    reserva_code: 'res7CODE',
    current_visitor: 1,
    num_visitor: 5,
  },
];

export const USERS_VALIDATED_MOCK: UserDTO[] = [
  {
    user_id: 'user1',
    rol: 'client',
    reserva_code: 'res1CODE',
    num_visitor: 1,
    reserve_type: BookingType.particular,
    visita_date: new Date().toLocaleDateString(),
  },
  {
    user_id: 'user2',
    rol: 'client',
    reserva_code: 'res2CODE',
    num_visitor: 2,
    reserve_type: BookingType.particular,
    visita_date: new Date().toLocaleDateString(),
  },
  {
    user_id: 'user8',
    rol: 'client',
    reserva_code: 'res8CODE',
    num_visitor: 8,
    reserve_type: BookingType.particular,
    visita_date: new Date().toLocaleDateString(),
  },
  {
    user_id: 'user9',
    rol: 'client',
    reserva_code: 'res9CODE',
    num_visitor: 9,
    reserve_type: BookingType.particular,
    visita_date: new Date().toLocaleDateString(),
  },
  {
    user_id: 'user10',
    rol: 'client',
    reserva_code: 'res1CODE0',
    num_visitor: 10,
    reserve_type: BookingType.particular,
    visita_date: new Date().toLocaleDateString(),
  },
  {
    user_id: 'user11',
    rol: 'client',
    reserva_code: 'res1CODE1',
    num_visitor: 11,
    reserve_type: BookingType.particular,
    visita_date: new Date().toLocaleDateString(),
  },
  {
    user_id: 'user12',
    rol: 'client',
    reserva_code: 'res1CODE2',
    num_visitor: 12,
    reserve_type: BookingType.particular,
    visita_date: new Date().toLocaleDateString(),
  },
];
