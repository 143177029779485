export const environment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: '11a5406d-6b92-42d0-94d0-f6cd0abd82d5',
    },
  },
  apiUrl: 'https://discoverycenter.madridingame.es:3000/api',
  useMocks: false,
  apiConfig: {
    scopes: ['https://graph.microsoft.com/Tasks.Read.All'],
    uri: 'https://madridingameb2c.azurewebsites.com/hello',
  },
  b2cPolicies: {
    names: {
      signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
      resetPassword: 'b2c_1_reset',
      editProfile: 'b2c_1_edit_profile',
    },
    authorities: {
      signUpSignIn: {
        authority:
          'https://madridingameb2c.b2clogin.com/madridingameb2c.onmicrosoft.com/B2C_1_LogInSignupEmail',
      },
      resetPassword: {
        authority:
          'https://madridingameb2c.b2clogin.com/madridingameb2c.onmicrosoft.com/B2C_1_ResetPassword',
      },
      editProfile: {
        authority:
          'https://madridingameb2c.b2clogin.com/madridingameb2c.onmicrosoft.com/B2C_1_EditProfile',
      },
    },
    authorityDomain: 'madridingameb2c.b2clogin.com',
  },
};
