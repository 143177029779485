<div
  class="fixed top-0 left-0 right-0 z-[998] d-flex justify-content-between align-items-center p-4"
>
  <div class="sm:flex-1" style="background-color: orange"></div>
  <div class="d-flex">
    <a href="https://eventos.madridingame.es/"
      ><img width="35" height="24" src="assets/icon1.svg" /></a
    ><a href="https://webesports.madridingame.es/" target="_blank"
      ><img width="35" height="24" src="assets/icon2.svg" /></a
    ><a href="https://www.madridingame.es/crear" target="_blank"
      ><img width="35" height="24" src="assets/icon3.svg"
    /></a>
    <!-- href="https://reservas.madridingame.es" -->
    <a target="_blank"><img width="35" height="24" src="assets/icon4.svg" /></a>
  </div>

  <div class="pe-6" style="flex: 1; display: flex; justify-content: flex-end">
    <img alt="Logo" height="80" src="assets/logo.png" />
  </div>
</div>
