export enum TimeSlot {
  morning = 'morning',
  afternoon = 'afternoon',
}

interface DailyAvailabilityProps {
  visitId: number;
  date: Date;
  timeSlot: TimeSlot;
  totalCapacity: number;
  reservedVisitors: number;
  availableSpots: number;
  maxGroup: number;
  duration: number;
}

export class DailyAvailability {
  readonly visitId: number;
  readonly date: Date;
  readonly timeSlot: TimeSlot;
  readonly totalCapacity: number;
  readonly reservedVisitors: number;
  readonly availableSpots: number;
  readonly maxGroup: number;
  readonly duration: number;

  private constructor(props: DailyAvailabilityProps) {
    this.visitId = props.visitId;
    this.date = props.date;
    this.timeSlot = props.timeSlot;
    this.totalCapacity = props.totalCapacity;
    this.reservedVisitors = props.reservedVisitors;
    this.availableSpots = props.availableSpots;
    this.maxGroup = props.maxGroup;
    this.duration = props.duration;
  }

  static create(props: DailyAvailabilityProps): DailyAvailability {
    return new DailyAvailability(props);
  }
}
