export enum AvailableType {
  Available = 'disponible',
  Half_Full = 'medio lleno',
}

interface MonthlyAvailabilityProps {
  date: Date;
  availability: AvailableType;
}

export class MonthlyAvailability {
  date: Date | null;
  availability: AvailableType;

  private constructor(props: MonthlyAvailabilityProps) {
    this.date = new Date(props.date);
    this.availability = props.availability;
  }

  static create(props: MonthlyAvailabilityProps): MonthlyAvailability {
    return new MonthlyAvailability(props);
  }
}
