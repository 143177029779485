import { BookingType } from 'src/app/shared/models/Booking';

export enum UserTypeEnum {
  Client = 'client',
  Admin = 'admin',
  Control = 'control',
  SuperAdmin = 'superadmin',
}

export const roleMap: Record<string, UserTypeEnum> = {
  client: UserTypeEnum.Client,
  admin: UserTypeEnum.Admin,
  control: UserTypeEnum.Control,
  superadmin: UserTypeEnum.SuperAdmin,
};

interface UserProps {
  id: string;
  role: UserTypeEnum;
  bookingCode?: string;
  visitorNum?: number;
  currentVisitor?: number;
  visitDate?: Date;
  bookingType?: BookingType;
}

export class User {
  readonly id: string;
  readonly role: UserTypeEnum;
  readonly bookingCode?: string;
  readonly visitorNum?: number;
  readonly currentVisitor?: number;
  readonly visitDate?: Date;
  readonly bookingType?: BookingType;

  private constructor(props: UserProps) {
    this.id = props.id;
    this.role = props.role;
    this.bookingCode = props.bookingCode;
    this.visitorNum = props.visitorNum;
    this.currentVisitor = props.currentVisitor;
    this.visitDate = props.visitDate;
    this.bookingType = props.bookingType;
  }

  static create(props: UserProps): User {
    return new User(props);
  }
}
