import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
  Visit,
  VisitCategoryEnum,
  ActivityStatusEnum,
} from 'src/app/shared/models';
import { VisitService } from 'src/app/shared/services';
import { VisitCardComponent } from 'src/app/visits';

type GroupedVisits = {
  [key in VisitCategoryEnum]?: Visit[];
};

@Component({
  selector: 'app-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss'],
})
export class VisitsComponent implements OnInit {
  private visitCardsSubscription!: Subscription;

  showLoading = true;

  visits: Visit[] = [];
  // normalVisits: Visit[] = [];
  // childVisits: Visit[] = [];

  VisitCategory: typeof VisitCategoryEnum = VisitCategoryEnum;
  // categorySelected: VisitCategoryEnum = VisitCategoryEnum.normal;

  @ViewChildren(VisitCardComponent) visitCards!: QueryList<VisitCardComponent>;

  constructor(
    private toastService: ToastrService,
    private visitService: VisitService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit() {
    this.visitCardsSubscription = this.visitCards.changes.subscribe(() => {
      this.scrollToClosestItem();
    });

    this.scrollToClosestItem();
  }

  ngOnDestroy() {
    if (this.visitCardsSubscription) {
      this.visitCardsSubscription.unsubscribe();
    }
  }

  async init(): Promise<void> {
    this.showLoading = true;
    try {
      const response = await this.visitService.getTodayVisits();
      this.visits = response;
      // const groupedData = this.groupVisitsByCategory(response);
      // this.normalVisits = groupedData.normal ?? [];
      // this.childVisits = groupedData.child ?? [];
    } catch (error) {
      let errorMessage = '';
      if (error instanceof HttpErrorResponse) {
        errorMessage = error.error.error;
      } else {
        errorMessage = 'No se han podido obtener datos';
      }
      this.toastService.error(errorMessage, 'Oops! Algo ha ido mal!!');
    } finally {
      this.showLoading = false;
    }
  }

  onCardClicked(visit: Visit): void {
    this.router.navigate(['/visitas/', visit.id]);
  }

  // groupVisitsByCategory(visits: Visit[]): GroupedVisits {
  //   const now = new Date();

  //   return visits.reduce((acc: GroupedVisits, visit: Visit) => {
  //     const category = visit.category;
  //     let newVisit = visit;
  //     if (visit.date < now) {
  //       newVisit = Visit.create({
  //         ...visit,
  //         activityStatus: ActivityStatusEnum.End,
  //       });
  //     }
  //     if (!acc[category]) {
  //       acc[category] = [];
  //     }
  //     acc[category]!.push(newVisit);
  //     return acc;
  //   }, {} as GroupedVisits);
  // }

  scrollToClosestItem() {
    if (this.visitCards && this.visitCards.length > 0) {
      const closestItem = this.visitCards.find(
        (itemRef: VisitCardComponent) => {
          if (!itemRef?.visit) {
            return false;
          }
          return itemRef.visit.activityStatus === ActivityStatusEnum.Waiting;
        },
      );

      if (closestItem) {
        closestItem.elementRef.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }
}
