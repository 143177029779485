import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'login-failed',
    templateUrl: './failed.component.html',
    styleUrls: ['./failed.component.scss']
})
export class FailedComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
