import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  NgbDate,
  NgbDateStruct,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import {
  AuthService,
  BookingService,
  isMonth,
  Month,
} from 'src/app/shared/services';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import {
  ApiConfig,
  AvailableType,
  Booking,
  BookingType,
  DailyAvailability,
  MonthlyAvailability,
  TimeSlot,
  User,
} from 'src/app/shared/models';
import {
  dateToNgbDateStruct,
  formatDateToSpanishString,
} from 'src/app/shared/utils';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

enum QuantityType {
  General = 'general',
  Special = 'special',
}

interface IdTokenClaims {
  extension_legalCountry?: string;
  extension_dateOfBirth?: string;
  postalCode?: string;
  emails?: string[];
  [key: string]: any; // Agrega esto para permitir propiedades adicionales si no estás seguro de todas las claves posibles
}

const DESKTOP_WIDTH = 1024;

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
})
export class BookingsComponent implements OnInit {
  loginDisplay = false;
  displayedColumns: string[] = ['claim', 'value'];
  dataSource: any = [];

  today = new Date();
  model: NgbDateStruct = {
    day: this.today.getDate(),
    month: this.today.getMonth() + 1,
    year: this.today.getFullYear(),
  };
  date: { year: number; month: number } = {
    year: this.today.getFullYear(),
    month: this.today.getMonth() + 1,
  };

  availableDates: NgbDateStruct[] = [];
  lowAvailabilityDates: NgbDateStruct[] = [];

  morningData: DailyAvailability[] = [];
  afternoonData: DailyAvailability[] = [];

  selectedTime?: DailyAvailability;

  outsideDays = 'visible';
  navigation = 'none';

  private readonly I18N_VALUES = {
    es: {
      weekdays: ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
    },
  };

  QuantityType: typeof QuantityType = QuantityType;
  TimeSlot: typeof TimeSlot = TimeSlot;

  timeSlotSelected?: TimeSlot;

  generalQuantity = 0;
  specialQuantity = 0;

  hasCalendarData = false;

  isAccepted = false;

  user?: User | null;

  hasActiveBooking = false;
  activeBooking?: Booking;
  apiConfig?: ApiConfig;

  private subscriptions: Subscription;

  @ViewChild('pickTheDate', { static: false }) pickTheDate!: ElementRef;
  @ViewChildren('pickTheTimes') pickTheTimes!: QueryList<ElementRef>;
  @ViewChildren('timeSlots') timeSlots!: QueryList<ElementRef>;

  constructor(
    private bookingService: BookingService,
    private modalService: NgbModal,
    private toastService: ToastrService,
    private authService: AuthService,
    private router: Router,
  ) {
    this.subscriptions = this.authService.user$.subscribe(
      (userLogged: User | null) => {
        this.user = userLogged;
        this.init();
      },
    );

    this.subscriptions = this.bookingService.apiConfig$.subscribe(
      (apiConfig: ApiConfig | null) => {
        if (!apiConfig) return;

        this.apiConfig = apiConfig;
        this.init();
      },
    );
  }

  ngOnInit(): void {
    this.executeDailyTask();
    this.init();
  }

  ngAfterViewInit() {
    const isMobile = window.innerWidth < DESKTOP_WIDTH;
    if (isMobile) {
      this.pickTheTimes.changes.subscribe(() => {
        this.scrollToElement(this.pickTheTimes);
      });
      this.timeSlots.changes.subscribe(() => {
        this.scrollToElement(this.timeSlots);
      });
    }
  }

   executeDailyTask() {
    const storageKey = 'lastExecution';
    const lastExecution = localStorage.getItem(storageKey);
    const today = new Date().toISOString().split('T')[0]; // Obtiene solo la fecha en formato YYYY-MM-DD

    if (lastExecution !== today) {
      // Actualiza la última fecha de ejecución en el almacenamiento local
      localStorage.setItem(storageKey, today);

      // Lógica de la función que se ejecutará una vez al día
      //console.log('Ejecutando tarea diaria...');
      this.updateUserData();
      // Aquí puedes colocar el código que deseas ejecutar una vez al día
    } else {
      //console.log('La tarea ya se ejecutó hoy.');
    }
  }


  async updateUserData(){
    try {
    const idTokenClaims = this.authService.msalService.instance.getActiveAccount()?.idTokenClaims as IdTokenClaims;
    if (idTokenClaims && idTokenClaims.emails && Array.isArray(idTokenClaims.emails) && idTokenClaims.emails.length > 0) {
      const legalCountry = idTokenClaims['extension_legalCountry'];
      const dateOfBirth = idTokenClaims['extension_dateOfBirth'];
      const postalCode = idTokenClaims['postalCode'];
      const sexo = idTokenClaims['extension_Gender'];
      const email = idTokenClaims.emails[0];
     const hasPostedKpis = await this.bookingService.postUserDataKpis({
          user_id: email,
          pais: legalCountry || '',
          edad: dateOfBirth || '',
          codigo_postal: postalCode || '',
          sexo: sexo || '',
        });

    } else {
      console.log("No active account found.");
    }
  } catch (error) {
    const storageKey = 'lastExecution';
    localStorage.setItem(storageKey, '');
    //console.error('Error posting user data KPIs:', error);
  }
  }

  async init(): Promise<void> {
    try {

      if (!this.user || !this.apiConfig) {
        return;
      }

      const hasActiveReservation = await this.bookingService.hasActiveBooking({
        user_id: this.user.id,
      });

      this.hasActiveBooking = hasActiveReservation;
      if (!hasActiveReservation) return;

      this.activeBooking = await this.bookingService.getRecentBooking({
        user_id: this.user.id,
      });
    } catch (error) {
      let errorMessage = '';
      if (error instanceof HttpErrorResponse) {
        errorMessage = error.error.error;
      } else {
        errorMessage = 'Error desconocido';
      }
      this.toastService.error(errorMessage, 'Oops! Algo ha ido mal!!');
    }
  }

  updateQuantity(quantity: number, type: QuantityType) {
    if (type === QuantityType.General) {
      this.generalQuantity =
        quantity <= (this.apiConfig?.generalReservationLimit ?? 0)
          ? quantity
          : this.apiConfig?.generalReservationLimit ?? 0;
    } else if (type === QuantityType.Special) {
      this.specialQuantity =
        quantity <= (this.apiConfig?.groupReservationLimit ?? 0)
          ? quantity
          : this.apiConfig?.groupReservationLimit ?? 0;
    }

    this.resetData();
    if (this.generalQuantity === 0 && this.specialQuantity === 0) {
      this.resetData();
    }
  }

  incrementQuantity(type: QuantityType) {
    if (type === QuantityType.General) {
      this.generalQuantity++;
    } else if (type === QuantityType.Special) {
      this.specialQuantity++;
    }

    this.resetData();
    if (this.generalQuantity === 0 && this.specialQuantity === 0) {
      this.resetData();
    }
  }

  decrementQuantity(type: QuantityType) {
    const quantities = {
      [QuantityType.General]: this.generalQuantity,
      [QuantityType.Special]: this.specialQuantity,
    };

    if (quantities[type] > 0) {
      quantities[type]--;
    }

    if (type === QuantityType.General) {
      this.generalQuantity = quantities[QuantityType.General];
    } else if (type === QuantityType.Special) {
      this.specialQuantity = quantities[QuantityType.Special];
    }

    this.resetData();
    if (this.generalQuantity === 0 && this.specialQuantity === 0) {
      this.resetData();
    }
  }

  openPrivacyPolicy(content: TemplateRef<HTMLElement>): void {
    this.openModal(content);
  }

  async openModal(
    content: TemplateRef<HTMLElement>,
    size: 'sm' | 'lg' | 'xl' | string = 'lg',
  ): Promise<void> {
    const modalRef: NgbModalRef = this.modalService.open(content, {
      centered: true,
      backdrop: 'static',
      size,
    });
    try {
      await modalRef.result;
    } catch (error) {
      // TODO: handle error
    } finally {
      return;
    }
  }

  async getCalendarData(): Promise<void> {
    // this.resetData();
    const bookingType = this.determineBookingType({
      generalQuantity: this.generalQuantity,
      specialQuantity: this.specialQuantity,
    });

    const visitors = this.visitorsSelected();

    try {
      if (!isMonth(this.model.month)) return;
      const month: Month = this.model.month;
      const response = await this.bookingService.getAvailableBookingsByMonth({
        reserve_type: bookingType,
        visite_size: visitors,
        month,
        year: this.model.year,
      });
      const { available, lowAvailable } = this.getAvailabilityData(response);
      this.availableDates = available;
      this.lowAvailabilityDates = lowAvailable;
      this.hasCalendarData = true;
      const isMobile = window.innerWidth < DESKTOP_WIDTH;
      if (isMobile) {
        this.scrollToCalendar();
      }
    } catch (error) {
      let errorMessage = '';
      if (error instanceof HttpErrorResponse) {
        errorMessage = error.error.error;
      } else {
        errorMessage = 'No se han podido obtener datos';
      }
      // this.toastService.error(errorMessage, 'Oops! Algo ha ido mal!!');
      this.availableDates = [];
      this.lowAvailabilityDates = [];
      this.hasCalendarData = true;
    }
  }

  scrollToCalendar() {
    if (this.pickTheDate) {
      setTimeout(() => {
        this.pickTheDate.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 0);
    }
  }

  scrollToElement(elements: QueryList<ElementRef>): void {
    const element = elements.first;
    if (element) {
      setTimeout(() => {
        element.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 10);
    }
  }

  determineBookingType({
    generalQuantity,
    specialQuantity,
  }: {
    generalQuantity: number;
    specialQuantity: number;
  }): BookingType {
    if (generalQuantity > 0 && specialQuantity === 0) {
      return BookingType.particular;
    }
    if (specialQuantity > 0 && generalQuantity === 0) {
      return BookingType.collective;
    }
    return BookingType.particular;
  }

  navigate(datepicker: NgbDatepicker, number: number): void {
    this.resetDataSelection();
    const { state, calendar } = datepicker;
    datepicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));

    const date: NgbDate = calendar.getNext(state.firstDate, 'm', number);
    this.model = {
      ...this.model,
      month: date.month,
    };
    this.getCalendarData();
  }

  trackByMonth(index: number, month: any): number {
    return month.month;
  }

  getMonthFullName(month: number): string {
    return this.I18N_VALUES.es.months[month - 1];
  }

  isAvailable(date: NgbDateStruct): boolean {
    return this.availableDates.some(
      (d) =>
        d.year === date.year && d.month === date.month && d.day === date.day,
    );
  }

  isLowAvailability(date: NgbDateStruct): boolean {
    return this.lowAvailabilityDates.some(
      (d) =>
        d.year === date.year && d.month === date.month && d.day === date.day,
    );
  }

  isDisabled(date: NgbDateStruct) {
    if (!this.hasCalendarData) return true;

    return !this.isAvailable(date) && !this.isLowAvailability(date);
  }

  getAvailabilityData(monthlyAvailabilities: MonthlyAvailability[]): {
    available: NgbDateStruct[];
    lowAvailable: NgbDateStruct[];
  } {
    const available: NgbDateStruct[] = [];
    const lowAvailable: NgbDateStruct[] = [];

    monthlyAvailabilities.forEach((availabilityEntry) => {
      const ngbDate = dateToNgbDateStruct(availabilityEntry.date!);
      if (availabilityEntry.availability === AvailableType.Available) {
        available.push(ngbDate);
      } else if (availabilityEntry.availability === AvailableType.Half_Full) {
        lowAvailable.push(ngbDate);
      }
    });

    return { available, lowAvailable };
  }

  async getAvailableTimes(date: NgbDateStruct): Promise<void> {
    this.resetDataSelection();
    const { day, month: monthNumber, year } = date;

    if (this.isDisabled(date)) {
      this.morningData = [];
      this.afternoonData = [];
      return;
    }
    const bookingType = this.determineBookingType({
      generalQuantity: this.generalQuantity,
      specialQuantity: this.specialQuantity,
    });

    try {
      if (!isMonth(monthNumber)) return;
      const visitors = this.visitorsSelected();
      const month: Month = monthNumber;
      const response = await this.bookingService.getAvailableBookingsByDay({
        reserve_type: bookingType,
        visite_size: visitors,
        day,
        month,
        year,
      });
      const groupedDailyData = this.groupByTimeSlot(response);
      this.morningData = groupedDailyData.morning ?? [];
      this.afternoonData = groupedDailyData.afternoon ?? [];
      const isMobile = window.innerWidth < DESKTOP_WIDTH;
      if (isMobile) {
        this.scrollToElement(this.pickTheTimes);
      }
    } catch (error) {
      let errorMessage = '';
      if (error instanceof HttpErrorResponse) {
        errorMessage = error.error.error;
      } else {
        errorMessage = 'Error desconocido';
      }
      this.toastService.error(errorMessage, 'Oops! Algo ha ido mal!!');
      this.morningData = [];
      this.afternoonData = [];
    }
  }

  groupByTimeSlot(
    availabilityArray: DailyAvailability[],
  ): Record<TimeSlot, DailyAvailability[]> {
    return availabilityArray.reduce((acc, availability) => {
      const { timeSlot } = availability;

      if (!acc[timeSlot]) {
        acc[timeSlot] = [];
      }

      acc[timeSlot].push(availability);

      return acc;
    }, {} as Record<TimeSlot, DailyAvailability[]>);
  }

  showTimeSlots(timeSlot: TimeSlot): void {
    this.timeSlotSelected = timeSlot;
    const isMobile = window.innerWidth < DESKTOP_WIDTH;
    if (isMobile) {
      this.scrollToElement(this.timeSlots);
    }
  }

  selectSlotData(dailySlot: DailyAvailability): void {
    this.selectedTime = dailySlot;
  }

  bookSlot(content: TemplateRef<HTMLElement>): void {
    this.modalService
      .open(content, { size: 'sm', centered: true, backdrop: 'static' })
      .result.then(() => {
        this.confirmBooking();
      });
  }

  async confirmBooking(): Promise<void> {
    if (!this.selectedTime || !this.user) return;

    const visitors = this.visitorsSelected();
    try {
      await this.bookingService.createBooking({
        user_id: this.user.id,
        visita_id: this.selectedTime.visitId,
        num_visitor: visitors,
      });

      this.toastService.success(
        this.displayBookingDate(),
        this.getQuantityText() ?? '',
      );

      this.navigateToMyBookings();
      document.body.scrollTop = 0;
    } catch (error) {
      let errorMessage = '';
      if (error instanceof HttpErrorResponse) {
        errorMessage = error.error.error;
      } else {
        errorMessage = 'Error desconocido';
      }
      this.toastService.error(errorMessage, 'Oops! Algo ha ido mal!!');
    }
  }

  navigateToMyBookings(): void {
    this.router.navigate(['/mis-reservas']);
  }

  getQuantityText(): string | void {
    if (this.generalQuantity > 0) {
      return `${this.generalQuantity}x Pases Generales`;
    }
    if (this.specialQuantity > 0) {
      return `${this.specialQuantity}x Pases Especiales`;
    }
  }

  displayBookingDate(): string {
    if (!this.selectedTime) {
      return '';
    }

    return formatDateToSpanishString(this.selectedTime.date);
  }

  resetData(): void {
    this.availableDates = [];
    this.lowAvailabilityDates = [];
    this.morningData = [];
    this.afternoonData = [];
    this.selectedTime = undefined;
    this.timeSlotSelected = undefined;
    this.hasCalendarData = false;
    this.isAccepted = false;
  }
  resetDataSelection(): void {
    // this.availableDates = [];
    // this.lowAvailabilityDates = [];
    this.morningData = [];
    this.afternoonData = [];
    this.selectedTime = undefined;
    this.timeSlotSelected = undefined;
    // this.hasCalendarData = false;
    this.isAccepted = false;
  }

  visitorsSelected(): number {
    let visitors = 0;
    if (this.generalQuantity > 0) {
      visitors = this.generalQuantity;
    }
    if (this.specialQuantity > 0) {
      visitors = this.specialQuantity;
    }
    return visitors;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
