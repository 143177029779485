<!-- TODO img && text -->
<div class="landing-container">
  <div class="container-img">
    <div class="d-flex justify-content-center">
      <h1 class="book-title font-MadridInGame col-10 col-xl-9">
        Reserva tus pases y ven a conocer el Campus Madrid in Game.
      </h1>
    </div>
    <div class="container-book-button d-flex justify-content-center">
      <button class="button-default font-Lato-bold py-1" (click)="book()">
        Reserva aquí <br />
        tus pases gratuitos
      </button>
    </div>
  </div>
  <div
    class="container-info d-flex flex-column pb-5 pt-3 mb-5 align-items-center justify-content-center"
  >
    <div class="col-10 col-xl-9">
      <h2 class="font-Lato-bold my-5">¡Bienvenidos a la Senda Estelar!</h2>
      <p class="text-xxxl font-Lato-bold">
        Delimitando el espacio geográfico del Campus del Videojuego, te
        invitamos a recorrer una senda multicolor sobre la que orbitan unos
        cuantos edificios en torno a la MIG Station. Completa las paradas
        marcadas, aprende qué se hace en cada uno de ellos y disfruta de
        experiencias tecnológicas espectaculares antes de subir a bordo de la
        MIG Station.
      </p>
      <div class="map-wrapper my-5">
        <img src="assets/images/landing-map.png" alt="landing map" />
      </div>
    </div>
  </div>
</div>
