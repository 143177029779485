<nav id="sidebar">
  <div class="p-4nav text-center">
    <ul class="list-unstyled components mb-5">
      <li class="active iconnav">
        <a href="https://www.madridingame.es/">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 25.9998V19.9998C19 19.7346 18.8946 19.4802 18.7071 19.2927C18.5196 19.1051 18.2652 18.9998 18 18.9998H14C13.7348 18.9998 13.4804 19.1051 13.2929 19.2927C13.1054 19.4802 13 19.7346 13 19.9998V25.9998C13 26.265 12.8946 26.5193 12.7071 26.7069C12.5196 26.8944 12.2652 26.9998 12 26.9998H6C5.73478 26.9998 5.48043 26.8944 5.29289 26.7069C5.10536 26.5193 5 26.265 5 25.9998V14.4373C5.00224 14.2989 5.03215 14.1623 5.08796 14.0357C5.14378 13.909 5.22437 13.7948 5.325 13.6998L15.325 4.61228C15.5093 4.44363 15.7501 4.3501 16 4.3501C16.2499 4.3501 16.4907 4.44363 16.675 4.61228L26.675 13.6998C26.7756 13.7948 26.8562 13.909 26.912 14.0357C26.9679 14.1623 26.9978 14.2989 27 14.4373V25.9998C27 26.265 26.8946 26.5193 26.7071 26.7069C26.5196 26.8944 26.2652 26.9998 26 26.9998H20C19.7348 26.9998 19.4804 26.8944 19.2929 26.7069C19.1054 26.5193 19 26.265 19 25.9998Z"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <p>Home</p>
        </a>
      </li>
      <li class="iconnav">
        <a href="https://www.madridingame.es/conocenos">
          <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.9947 24.5C6.02669 24.6314 6.08469 24.7551 6.1653 24.8638C6.2459 24.9725 6.34747 25.0639 6.46399 25.1326C6.58052 25.2014 6.70964 25.2461 6.84373 25.2641C6.97782 25.2821 7.11416 25.273 7.2447 25.2375C13.2034 23.5875 19.4985 23.5875 25.4572 25.2375C25.5877 25.273 25.7241 25.2821 25.8582 25.2641C25.9923 25.2461 26.1214 25.2014 26.2379 25.1326C26.3544 25.0639 26.456 24.9725 26.5366 24.8638C26.6172 24.7551 26.6752 24.6314 26.7072 24.5L29.8947 10.9625C29.9385 10.7813 29.9307 10.5914 29.8723 10.4144C29.8139 10.2374 29.7072 10.0802 29.5642 9.9606C29.4212 9.84101 29.2476 9.76377 29.0631 9.73759C28.8785 9.71141 28.6903 9.73734 28.5197 9.81246L22.1947 12.625C21.9655 12.7259 21.7071 12.7383 21.4693 12.6598C21.2315 12.5813 21.0313 12.4175 20.9072 12.2L17.2322 5.57496C17.1457 5.41866 17.019 5.28837 16.8651 5.19765C16.7112 5.10693 16.5358 5.05908 16.3572 5.05908C16.1786 5.05908 16.0032 5.10693 15.8493 5.19765C15.6954 5.28837 15.5687 5.41866 15.4822 5.57496L11.8072 12.2C11.6831 12.4175 11.4829 12.5813 11.2451 12.6598C11.0073 12.7383 10.7489 12.7259 10.5197 12.625L4.1947 9.81246C4.02409 9.73734 3.83588 9.71141 3.65132 9.73759C3.46676 9.76377 3.29318 9.84101 3.15019 9.9606C3.0072 10.0802 2.90048 10.2374 2.84209 10.4144C2.78369 10.5914 2.77593 10.7813 2.8197 10.9625L5.9947 24.5Z"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <p>Conócenos</p>
        </a>
      </li>
      <li class="iconnav">
        <a href="https://eventos.madridingame.es/">
          <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M26.8572 5H6.85718C6.30489 5 5.85718 5.44772 5.85718 6V26C5.85718 26.5523 6.30489 27 6.85718 27H26.8572C27.4095 27 27.8572 26.5523 27.8572 26V6C27.8572 5.44772 27.4095 5 26.8572 5Z"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22.8572 3V7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.8572 3V7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.85718 11H27.8572" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <p>Eventos</p>
        </a>
      </li>
      <li class="iconnav">
        <a href="https://www.madridingame.es/noticias">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 16L16 8V24L29 16" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <p>Noticias</p>
        </a>
      </li>
      <!-- <li class="iconnav">
        <a href="./login.html">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M11.5 15C12.3284 15 13 14.3284 13 13.5C13 12.6716 12.3284 12 11.5 12C10.6716 12 10 12.6716 10 13.5C10 14.3284 10.6716 15 11.5 15Z"
              fill="#969696" />
            <path d="M19 13.5H22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M21.2 19C20.6714 19.9107 19.9128 20.6667 19.0002 21.1922C18.0876 21.7176 17.053 21.9942 16 21.9942C14.9469 21.9942 13.9123 21.7176 12.9998 21.1922C12.0872 20.6667 11.3286 19.9107 10.8 19"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <p>Login</p>
        </a>
      </li> -->
      <li class="iconnav">
        <a href="javascript:void(0)" class="submenuitemsocial">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 20C10.2091 20 12 18.2091 12 16C12 13.7909 10.2091 12 8 12C5.79086 12 4 13.7909 4 16C4 18.2091 5.79086 20 8 20Z"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M22 29C24.2091 29 26 27.2091 26 25C26 22.7909 24.2091 21 22 21C19.7909 21 18 22.7909 18 25C18 27.2091 19.7909 29 22 29Z"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M22 11C24.2091 11 26 9.20914 26 7C26 4.79086 24.2091 3 22 3C19.7909 3 18 4.79086 18 7C18 9.20914 19.7909 11 22 11Z"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18.6375 9.1626L11.3625 13.8376" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.3625 18.1626L18.6375 22.8376" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <p>Social</p>
        </a>
      </li>
      <div id="social" class="hide">
        <ul>
          <li class="iconnav">
            <a href="https://x.com/MadridinGame">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 4H11.8488L28 28H21.1512L5 4Z" stroke="#969696" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"></path>
                <path d="M26 4L18.3701 13M6 28L14.2392 18.1134" stroke="#969696" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"></path>
              </svg>
              <p>X</p>
            </a>
          </li>
          <li class="iconnav">
            <a href="https://www.facebook.com/madridingame/">
              <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.5 28C23.1274 28 28.5 22.6274 28.5 16C28.5 9.37258 23.1274 4 16.5 4C9.87258 4 4.5 9.37258 4.5 16C4.5 22.6274 9.87258 28 16.5 28Z"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M21.5 11H19.5C19.1056 10.9984 18.7147 11.0748 18.35 11.225C17.9853 11.3752 17.6539 11.5961 17.375 11.875C17.0961 12.1539 16.8752 12.4853 16.725 12.85C16.5748 13.2147 16.4984 13.6056 16.5 14V28"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.5 18H20.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p>Facebook</p>
            </a>
          </li>
          <li class="iconnav">
            <a href="https://www.twitch.tv/madridingame">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.6375 25H15.3625C15.1284 25.0001 14.9021 25.0844 14.725 25.2375L9 30V25H6C5.73478 25 5.48043 24.8946 5.29289 24.7071C5.10536 24.5196 5 24.2652 5 24V6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H26C26.2652 5 26.5196 5.10536 26.7071 5.29289C26.8946 5.48043 27 5.73478 27 6V19.5375C26.998 19.6832 26.9646 19.8267 26.902 19.9583C26.8395 20.0899 26.7492 20.2065 26.6375 20.3L21.275 24.7625C21.0979 24.9156 20.8716 24.9999 20.6375 25V25Z"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M21 11V17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15 11V17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p>Twitch</p>
            </a>
          </li>
          <li class="iconnav">
            <a href="https://www.instagram.com/madridingame/">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z"
                  stroke-width="2" stroke-miterlimit="10" />
                <path
                  d="M21.5 4.5H10.5C7.18629 4.5 4.5 7.18629 4.5 10.5V21.5C4.5 24.8137 7.18629 27.5 10.5 27.5H21.5C24.8137 27.5 27.5 24.8137 27.5 21.5V10.5C27.5 7.18629 24.8137 4.5 21.5 4.5Z"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M22.5 11C23.3284 11 24 10.3284 24 9.5C24 8.67157 23.3284 8 22.5 8C21.6716 8 21 8.67157 21 9.5C21 10.3284 21.6716 11 22.5 11Z"
                  fill="#969696" />
              </svg>
              <p>Instagram</p>
            </a>
          </li>
          <li class="iconnav">
            <a href="https://www.linkedin.com/company/madrid-in-game/">
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M23.5 1.5H2.5C1.94772 1.5 1.5 1.94772 1.5 2.5V23.5C1.5 24.0523 1.94772 24.5 2.5 24.5H23.5C24.0523 24.5 24.5 24.0523 24.5 23.5V2.5C24.5 1.94772 24.0523 1.5 23.5 1.5Z"
                  stroke="#969696" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M8 11V19" stroke="#969696" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                </path>
                <path
                  d="M12 14.5C12 13.5717 12.3687 12.6815 13.0251 12.0251C13.6815 11.3687 14.5717 11 15.5 11C16.4283 11 17.3185 11.3687 17.9749 12.0251C18.6313 12.6815 19 13.5717 19 14.5V19"
                  stroke="#969696" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                  d="M8 8.5C8.82843 8.5 9.5 7.82843 9.5 7C9.5 6.17157 8.82843 5.5 8 5.5C7.17157 5.5 6.5 6.17157 6.5 7C6.5 7.82843 7.17157 8.5 8 8.5Z"
                  fill="#969696"></path>
              </svg>
              <p>LinkedIn</p>
            </a>
          </li>
          <li class="iconnav">
            <a href="https://www.youtube.com/@MadridinGame">
              <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 11L12 7V15L18 11Z" stroke="#969696" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"></path>
                <path
                  d="M1 10.9999C1 14.7249 1.3875 16.8999 1.675 18.0249C1.75427 18.3301 1.90418 18.6125 2.11262 18.8491C2.32106 19.0858 2.58221 19.2702 2.875 19.3874C7.0625 20.9874 14 20.9499 14 20.9499C14 20.9499 20.9375 20.9874 25.125 19.3874C25.4178 19.2702 25.6789 19.0858 25.8874 18.8491C26.0958 18.6125 26.2457 18.3301 26.325 18.0249C26.6125 16.8999 27 14.7249 27 10.9999C27 7.27488 26.6125 5.09988 26.325 3.97488C26.2457 3.66963 26.0958 3.38729 25.8874 3.15062C25.6789 2.91395 25.4178 2.72958 25.125 2.61238C20.9375 1.01238 14 1.04988 14 1.04988C14 1.04988 7.0625 1.01238 2.875 2.61238C2.58221 2.72958 2.32106 2.91395 2.11262 3.15062C1.90418 3.38729 1.75427 3.66963 1.675 3.97488C1.3875 5.09988 1 7.27488 1 10.9999Z"
                  stroke="#969696" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              <p>Youtube</p>
            </a>
          </li>
        </ul>
      </div>
      <li class="iconnav">
        <a href="javascript:void(-1)" class="submenuitem">
          <svg width="31" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 16H27" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 8H27" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 24H27" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <p>Más</p>
        </a>
      </li>
      <div id="submenu" class="hide">
        <ul>
          <li class="iconnav">
            <a href="https://www.madridingame.es/observatorio">
              <svg width="32" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M26.8572 6H5.85718C5.30489 6 4.85718 6.44772 4.85718 7V22C4.85718 22.5523 5.30489 23 5.85718 23H27.8572C28.4095 23 28.8572 22.5523 28.8572 22V7C28.8572 6.44772 28.4095 6 27.8572 6Z"
                  stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.8572 23L24.8572 28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.8572 23L8.85718 28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.8572 15V18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.8572 13V18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.8572 11V18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.8572 6V3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p>Observatorio</p>
            </a>
          </li>

          <li class="iconnav">
            <a href="https://www.madridingame.es/prensa">
              <svg width="32" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8572 14H22.8572" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.8572 18H22.8572" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M3.85718 25C5.38761 25 5.89632 24.7893 6.27139 24.4142C6.64646 24.0391 6.85718 23.5304 6.85718 23V8C6.85718 7.73478 6.96253 7.48043 7.15007 7.29289C7.33761 7.10536 7.59196 7 7.85718 7H27.8572C28.1224 7 28.3767 7.10536 28.5643 7.29289C28.7518 7.48043 28.8572 7.73478 28.8572 8V23C28.8572 23.5304 28.6465 24.0391 28.2714 24.4142C27.8963 24.7893 27.3876 25 26.8572 25H4.85718Z"
                  stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M3.85718 25C4.32674 25 3.81804 24.7893 3.44296 24.4142C3.06789 24.0391 2.85718 23.5304 2.85718 23V11"
                  stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p>Prensa</p>
            </a>
          </li>
          <li class="iconnav">
            <a href="https://www.madridingame.es/contacto">
              <svg width="32" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.85718 7H28.8572V24C28.8572 24.2652 28.7518 24.5196 28.5643 24.7071C28.3767 24.8946 28.1224 25 27.8572 25H5.85718C5.59196 25 5.33761 24.8946 5.15007 24.7071C4.96253 24.5196 4.85718 24.2652 4.85718 24V7Z"
                  stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M27.8572 7L16.8572 18L4.85718 7" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>

              <p>Contacto</p>
            </a>
          </li>
          <li class="iconnav">
            <a href="https://www.madridingame.es/newsletter">
              <svg width="31" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M25.2875 4.48746L2.9875 11.05C2.78915 11.1042 2.61235 11.2182 2.48117 11.3766C2.34999 11.5349 2.2708 11.7298 2.25441 11.9348C2.23801 12.1398 2.28519 12.3448 2.38953 12.522C2.49388 12.6992 2.6503 12.8399 2.8375 12.925L13.5375 17.9875C13.7471 18.0845 13.9154 18.2529 14.0125 18.4625L19.075 29.1625C19.1601 29.3497 19.3008 29.5061 19.478 29.6104C19.6551 29.7148 19.8602 29.7619 20.0651 29.7456C20.2701 29.7292 20.465 29.65 20.6234 29.5188C20.7817 29.3876 20.8958 29.2108 20.95 29.0125L27.5125 5.71246C27.5622 5.54243 27.5653 5.36216 27.5214 5.19053C27.4775 5.01891 27.3882 4.86225 27.263 4.73699C27.1377 4.61172 26.9811 4.52246 26.8094 4.47856C26.6378 4.43466 26.4575 4.43773 26.2875 4.48746V4.48746Z"
                  stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.8625 18.1373L19.5125 12.4873" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <p>Newsletter</p>
            </a>
          </li>
        </ul>
      </div>
    </ul>
  </div>
</nav>