import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiConfig, Booking, BookingType, User } from 'src/app/shared/models';
import { AuthService, BookingService } from 'src/app/shared/services';

@Component({
  selector: 'app-my-bookings',
  templateUrl: './my-bookings.component.html',
  styleUrls: ['./my-bookings.component.scss'],
})
export class MyBookingsComponent implements OnInit {
  private subscriptions: Subscription;
  booking: Booking | null = null;
  showLoading = false;
  openEditMode = false;
  quantity = 0;
  generalReservationLimit = 0;
  groupReservationLimit = 0;

  BookingType: typeof BookingType = BookingType;

  constructor(
    private authService: AuthService,
    private bookingService: BookingService,
    private modalService: NgbModal,
    private toastService: ToastrService,
  ) {
    this.subscriptions = this.authService.user$.subscribe(
      (user: User | null) => {
        if (user) {
          this.getBookingsByUserId(user.id);
        }
      },
    );

    this.subscriptions = this.bookingService.apiConfig$.subscribe(
      (apiConfig: ApiConfig | null) => {
        if (!apiConfig) return;
        this.generalReservationLimit = apiConfig.generalReservationLimit;
        this.groupReservationLimit = apiConfig.groupReservationLimit;
      },
    );
  }

  ngOnInit(): void {}

  checkMaxVisitors(booking: Booking) {
    return booking.reserveType === BookingType.collective
      ? this.groupReservationLimit
      : this.generalReservationLimit;
  }

  async getBookingsByUserId(userId: string) {
    this.showLoading = true;
    try {
      if (userId) {
        const booking = await this.bookingService.getBookingByUserId(userId);
        this.booking = booking;
        this.quantity = booking.numVisitor;
      }
    } catch (err) {
      // error: "No se encontró ninguna reserva para el usuario dado"
      this.booking = null;
    }
    this.showLoading = false;
  }

  onEdit() {
    this.openEditMode = !this.openEditMode;
  }

  incrementQuantity() {
    this.quantity++;
  }

  decrementQuantity() {
    if (this.quantity > 0) {
      this.quantity--;
    }
  }

  updateQuantity(quantity: number) {
    const maxVisitors = this.booking && this.checkMaxVisitors(this.booking);
    if (maxVisitors && this.booking && quantity > maxVisitors) {
      this.quantity = maxVisitors;
    } else {
      this.quantity = quantity;
    }
  }

  modifyIsAvailable(booking: Booking) {
    const maxVisitors =
      booking.reserveType === BookingType.particular
        ? this.generalReservationLimit
        : this.groupReservationLimit;

    return (
      this.quantity > 0 &&
      this.quantity <= maxVisitors &&
      booking.numVisitor !== this.quantity
    );
  }

  async modify(booking: Booking) {
    if (!booking || !this.modifyIsAvailable(booking)) {
      return;
    }

    try {
      await this.bookingService.updateBooking(booking.code, this.quantity);
      this.getBookingsByUserId(booking?.userId);
      this.openEditMode = false;
      this.toastService.success('Reserva modificada correctamente.');
    } catch (err) {
      this.toastService.error('Error al intentar modificar la reserva.');
    }
  }

  async delete() {
    if (!this.booking) return;
    try {
      await this.bookingService.deleteBooking(this.booking.code);
      this.getBookingsByUserId(this.booking.userId);
      this.toastService.success('Reserva borrada correctamente.');
    } catch (err) {
      this.toastService.error('Error al intentar borrar la reserva.');
    }
    this.openEditMode = false;
    this.modalService.dismissAll();
  }

  async cancelBooking(content: TemplateRef<HTMLElement>) {
    await this.openModal(content, 'sm');
  }

  async openModal(
    content: TemplateRef<HTMLElement>,
    size: 'sm' | 'lg' | 'xl' | string = 'lg',
  ): Promise<void> {
    const modalRef: NgbModalRef = this.modalService.open(content, {
      centered: true,
      backdrop: 'static',
      size,
    });
    try {
      await modalRef.result;
    } catch (error) {
      // TODO: handle error
    } finally {
      return;
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
